import React from "react";
import {
  Check,
  Close,
  HorizontalSplit,
  MoreVert,
  SettingsApplications,
  VerticalSplit,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { AlphaICircleOutline, FileTreeOutline } from "mdi-material-ui";
import { useIsMobile } from "~/layout";
import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { getEventHandlerProps } from "~/utils";
import { usePlayerActions } from "../actions";
import { usePlayerTopics } from "../hooks";
import { useOnDemandInference } from "../inference";
import { isPanelImageOperationSource } from "../inference/on-demand-inference/use-operation-controller";
import { useControlledPanelContext } from "../layout";
import type { InitializedPanelNode } from "../panels";
import {
  getSupportedVisualizations,
  SplitOrientation,
  usePanelContext,
  VisualizationType,
} from "../panels";
import { getTopicContextDisplayName } from "./utils";

export function PanelHeader() {
  const playerTopicsQuery = usePlayerTopics();

  const panel = usePanelContext();

  const isMobile = useIsMobile();
  const iconFontSize = isMobile ? "small" : "medium";

  const controlledPanelContext = useControlledPanelContext({ strict: false });
  const isVisible = controlledPanelContext?.panelId === panel.id;

  const playerActions = usePlayerActions();

  const onDemandInference = useOnDemandInference({ strict: false });
  const isInferenceSource =
    onDemandInference !== undefined &&
    isPanelImageOperationSource(onDemandInference.operation, panel);

  const popupState = usePopupState({
    variant: "popover",
    popupId: `panel-${panel.id}-menu`,
  });

  function createVisualizationChangeHandler(tab: VisualizationType) {
    return function handleVisualizationChange() {
      playerActions.chooseVisualization(panel, tab);
    };
  }

  function handleClearTopic() {
    playerActions.clearPanelTopic(panel);
  }

  function createSplitHandler(orientation: SplitOrientation) {
    return function handleSplit() {
      playerActions.splitPanel(panel, orientation);
    };
  }

  function handleClose() {
    playerActions.closePanel(panel);
  }

  function createControlsToggleHandler(initializedPanel: InitializedPanelNode) {
    return function handleToggleControls() {
      playerActions.togglePanelControls(initializedPanel);
    };
  }

  const supportedVisualizations = getSupportedVisualizations(panel);

  function renderMenuVisItem(
    key: number,
    text: string,
    value: VisualizationType,
  ) {
    invariant(panel.isInitialized, "Can only render vis items for vis panel");

    const isCurrent = panel.visualization === value;

    const visualizationChangeHandlerProps = getEventHandlerProps(
      "onClick",
      supportedVisualizations[value] && createVisualizationChangeHandler(value),
    );

    return (
      <MenuItem key={key} {...visualizationChangeHandlerProps}>
        {isCurrent && (
          <ListItemIcon>
            <Check />
          </ListItemIcon>
        )}
        <ListItemText inset={!isCurrent}>{text}</ListItemText>
      </MenuItem>
    );
  }

  return (
    <Stack direction="row" spacing={isMobile ? 0.5 : 1} alignItems="center">
      <Tooltip title="Open panel settings">
        <IconButton {...bindTrigger(popupState)} size="small">
          <MoreVert fontSize={iconFontSize} />
        </IconButton>
      </Tooltip>
      {panel.isInitialized ? (
        <Typography
          fontWeight="bold"
          noWrap
          variant={isMobile ? "body2" : "body1"}
        >
          {getEffectiveTopicName(playerTopicsQuery, panel.topicName)}
        </Typography>
      ) : (
        <Typography fontStyle="italic" noWrap>
          No topic selected
        </Typography>
      )}
      {panel.isInitialized && (
        <Stack
          direction="row"
          spacing={1}
          sx={{ ml: "auto", alignItems: "center" }}
        >
          {isInferenceSource && onDemandInference.status !== "idle" && (
            <Tooltip title="On-demand inference source">
              <AlphaICircleOutline fontSize={iconFontSize} />
            </Tooltip>
          )}
          {controlledPanelContext !== undefined && (
            <Tooltip title="Panel controls">
              <IconButton
                size="small"
                onClick={createControlsToggleHandler(panel)}
              >
                <SettingsApplications
                  color={isVisible ? "primary" : undefined}
                  fontSize={iconFontSize}
                />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
      <Menu
        MenuListProps={{
          dense: isMobile,
        }}
        {...bindPopover(popupState)}
        slotProps={{
          paper: {
            sx: {
              width: 300,
              "& .MuiDivider-root": {
                my: 1,
              },
            },
          },
        }}
      >
        {/* MUI won't let you pass a fragment as a Menu's child so you
          have to use an array instead */}
        {panel.isInitialized && [
          <MenuItem key={0} onClick={handleClearTopic}>
            <ListItemIcon>
              <FileTreeOutline />
            </ListItemIcon>
            <ListItemText>Choose new topic</ListItemText>
          </MenuItem>,
          <Divider key={1} component="li" />,
          renderMenuVisItem(2, "Timeline", VisualizationType.Timeline),
          renderMenuVisItem(3, "Chart", VisualizationType.Chart),
          renderMenuVisItem(4, "Image", VisualizationType.Image),
          renderMenuVisItem(5, "Map", VisualizationType.Map),
          renderMenuVisItem(6, "3D", VisualizationType.ThreeD),
          <Divider key={7} component="li" />,
        ]}
        <MenuItem onClick={createSplitHandler(SplitOrientation.Vertical)}>
          <ListItemIcon>
            <VerticalSplit />
          </ListItemIcon>
          <ListItemText>Split panel right</ListItemText>
        </MenuItem>
        <MenuItem onClick={createSplitHandler(SplitOrientation.Horizontal)}>
          <ListItemIcon>
            <HorizontalSplit />
          </ListItemIcon>
          <ListItemText>Split panel down</ListItemText>
        </MenuItem>
        <Divider component="li" />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Close />
          </ListItemIcon>
          <ListItemText>Close panel</ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  );
}

function getEffectiveTopicName(
  playerTopicsQuery: UseQueryResult<ReadonlyArray<Topic>>,
  panelTopicName: InitializedPanelNode["topicName"],
): string {
  // This query should always be successful by this point otherwise
  // <PanelHeader /> shouldn't have been rendered.
  const topic = playerTopicsQuery.data?.find(
    (topic) => topic.name === panelTopicName,
  );
  if (topic == null) {
    return panelTopicName;
  }

  return getTopicContextDisplayName(topic.context) ?? panelTopicName;
}
