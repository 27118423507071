import React, { useState } from "react";
import {
  ExpandLess,
  ExpandMore,
  Public,
  Search,
  Tune,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  collapseClasses,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@mui/material";
import type { Control } from "react-hook-form";
import type { ValueOf } from "type-fest";
import * as z from "zod";
import { ComboBox, TextField, useStudioForm } from "~/components/Form";
import type { Option } from "~/components/Form/types";
import { Pagination } from "~/components/Pagination";
import { useSearchRequest } from "~/components/Table";
import {
  filterArray,
  filterText,
  filterUuid,
  requiredUuid,
} from "~/domain/common";
import { useAllGroups, useAllLabels } from "~/domain/logs";
import { useIsMobile } from "~/layout";
import type {
  GroupListResponse,
  LabelListResponse,
  ListLogsRequest,
} from "~/lqs";
import type { LabelProcessor } from "~/pages/datastore-dashboard";
import {
  DataStoreDashboardPage,
  LogMap,
  LogThumbnails,
  useLogMapQuery,
  useLogThumbnailsQuery,
  useMobileFilters,
} from "~/pages/datastore-dashboard";
import type { Maybe } from "~/types";
import { pluralize } from "~/utils/pluralize";

const LIMIT_OPTIONS = [15, 25, 50];

const SortOptions = {
  Newest: "newest",
  Oldest: "oldest",
} as const;

const SENSOR_LABEL_NAMES = new Set([
  "KS21",
  "KS21i",
  "S27",
  "S30",
  "Duro",
  "S25 Thermal",
]);

const filterSchema = z.object({
  name: filterText,
});

const requestSchema = filterSchema
  .extend({
    // We're planning to support multiple groups eventually so this is plural
    // to be forward-compatible even though it currently won't accept multiple
    groupIds: filterUuid.catch(null),
    sensors: filterArray(requiredUuid).catch([]),
    labelIds: filterArray(requiredUuid).catch([]),
    limit: z.coerce
      .number()
      .refine((arg) => LIMIT_OPTIONS.includes(arg))
      .catch(LIMIT_OPTIONS[1]),
    offset: z.coerce.number().int().nonnegative().catch(0),
    sort: z.nativeEnum(SortOptions).catch(SortOptions.Newest),
  })
  .transform((arg) => {
    // Offset should be multiple of limit
    const isValidOffset = arg.offset % arg.limit === 0;

    return {
      ...arg,
      offset: isValidOffset ? arg.offset : 0,
    };
  });

function useLogsSearchRequest() {
  return useSearchRequest(requestSchema);
}

type LogsSearchRequest = ReturnType<typeof useLogsSearchRequest>[0];
type SetLogsSearchRequest = ReturnType<typeof useLogsSearchRequest>[1];

function LogNumberField({
  control,
}: {
  control: Control<{ name: string | null }>;
}) {
  return (
    <TextField
      control={control}
      name="name"
      label="Log number"
      size="small"
      noHelperText
      endAdornment={
        <InputAdornment position="end">
          <Tooltip title="Search">
            <IconButton type="submit" edge="end" size="small">
              <Search fontSize="small" />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      }
    />
  );
}

function SortField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleSortChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setRequest({ sort: e.target.value as any });
  }

  return (
    <MuiTextField
      select
      size="small"
      label="Sort by"
      value={request.sort}
      onChange={handleSortChange}
      sx={{ width: "15ch", flex: "none" }}
    >
      <MenuItem value={SortOptions.Newest}>Newest</MenuItem>
      <MenuItem value={SortOptions.Oldest}>Oldest</MenuItem>
    </MuiTextField>
  );
}

const sortMapping: Record<
  ValueOf<typeof SortOptions>,
  Pick<ListLogsRequest, "sort" | "order">
> = {
  [SortOptions.Newest]: { sort: "desc", order: "start_time" },
  [SortOptions.Oldest]: { sort: "asc", order: "start_time" },
};

function PaginationControls({
  disableJumping,
  logCount,
  request,
  setRequest,
}: {
  disableJumping?: boolean;
  logCount: number;
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleLimitChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setRequest({ limit: Number(e.target.value) });
  }

  function handleOffsetChange(newOffset: number): void {
    setRequest({ offset: newOffset });
  }

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <MuiTextField
        select
        size="small"
        label="Results per page"
        value={request.limit}
        onChange={handleLimitChange}
        sx={{ width: "15ch" }}
      >
        {LIMIT_OPTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </MuiTextField>
      <Pagination
        disableJumping={disableJumping}
        count={logCount}
        limit={request.limit}
        offset={request.offset}
        onChange={handleOffsetChange}
      />
    </Stack>
  );
}

function GroupsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  const optionsQuery = useAllGroups({ select: selectGroupOptions });

  function handleChange(newGroupId: string | null): void {
    setRequest({ groupIds: newGroupId });
  }

  return (
    <ComboBox
      name="group"
      value={request.groupIds}
      onChange={handleChange}
      optionsQuery={optionsQuery}
      size="small"
      noHelperText
    />
  );
}

function selectGroupOptions(
  response: GroupListResponse,
): ReadonlyArray<Option> {
  return response.data.map((group) => ({
    value: group.id,
    label: group.name,
  }));
}

function SensorsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  const optionsQuery = useAllLabels({ select: selectSensorOptions });

  function handleChange(newSensors: Array<string>): void {
    setRequest({ sensors: newSensors });
  }

  let value = request.sensors;
  if (optionsQuery.isSuccess) {
    // It's possible someone could craft a URL where the `sensors` search param
    // contains a valid label ID that isn't one of the hard-coded sensor labels.
    // In such a case, just filter the value out so it doesn't show up in the
    // combobox. This doesn't prevent the label ID from being used to make the
    // log list request, so it's possible for logs to be filtered by a "sensor"
    // someone can't see in the UI. I consider that acceptable (for now).
    value = value.filter((labelId) =>
      optionsQuery.data.some((option) => option.value === labelId),
    );
  }

  return (
    <ComboBox
      name="sensors"
      value={value}
      onChange={handleChange}
      optionsQuery={optionsQuery}
      size="small"
      noHelperText
    />
  );
}

function selectSensorOptions(
  response: LabelListResponse,
): ReadonlyArray<Option> {
  return response.data.flatMap((label) => {
    if (!SENSOR_LABEL_NAMES.has(label.value)) {
      return [];
    }

    return {
      value: label.id,
      label: label.value,
    };
  });
}

function LabelsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  const optionsQuery = useAllLabels({ select: selectLabelOptions });

  function handleChange(newLabelIds: Array<string>): void {
    setRequest({ labelIds: newLabelIds });
  }

  let value = request.labelIds;
  if (optionsQuery.isSuccess) {
    // See comment in <SensorsField /> for reasoning, though in this case
    // someone could craft a URL where a `labelIds` search param contains a
    // valid label ID for one of the hard-coded sensor labels.
    value = value.filter((labelId) =>
      optionsQuery.data.some((option) => option.value === labelId),
    );
  }

  return (
    <ComboBox
      name="tags"
      value={value}
      onChange={handleChange}
      optionsQuery={optionsQuery}
      size="small"
      noHelperText
    />
  );
}

function selectLabelOptions(
  response: LabelListResponse,
): ReadonlyArray<Option> {
  return response.data.flatMap((label) => {
    if (SENSOR_LABEL_NAMES.has(label.value)) {
      return [];
    }

    return {
      value: label.id,
      label: label.value,
    };
  });
}

function ThumbnailsFilters({
  request,
  setRequest,
  logCount,
  onOpenMap,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
  logCount: Maybe<number>;
  onOpenMap: () => void;
}) {
  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: { name: request.name },
    onSubmit: setRequest,
  });

  const isMobile = useIsMobile();

  const mobileFilters = useMobileFilters();

  if (isMobile) {
    return (
      <>
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          <Tooltip title="Filters">
            <IconButton size="small" onClick={mobileFilters.toggle}>
              <Tune fontSize="small" />
            </IconButton>
          </Tooltip>
          <Typography>
            {logCount != null && pluralize(logCount, "log")}
          </Typography>
          <Button
            sx={{ ml: "auto" }}
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            startIcon={<Public />}
            onClick={onOpenMap}
          >
            Open Map
          </Button>
        </Stack>
        <Collapse in={mobileFilters.show}>
          <Stack spacing={1.5} sx={{ mt: 1.5 }}>
            <GroupsField request={request} setRequest={setRequest} />
            <SensorsField request={request} setRequest={setRequest} />
            <LabelsField request={request} setRequest={setRequest} />
            <Stack direction="row" spacing={1.5} sx={{ alignItems: "center" }}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ width: "100%" }}
              >
                <LogNumberField control={control} />
              </Box>
              <SortField request={request} setRequest={setRequest} />
            </Stack>
          </Stack>
        </Collapse>
      </>
    );
  } else {
    return (
      <Stack
        direction="row"
        spacing={1.5}
        sx={{ alignItems: "center", flexWrap: "wrap" }}
      >
        <Box sx={{ width: "27ch" }}>
          <GroupsField request={request} setRequest={setRequest} />
        </Box>
        <Box sx={{ width: "25ch" }}>
          <SensorsField request={request} setRequest={setRequest} />
        </Box>
        <Box sx={{ width: "35ch" }}>
          <LabelsField request={request} setRequest={setRequest} />
        </Box>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ width: "17ch" }}
        >
          <LogNumberField control={control} />
        </Box>
        <Typography sx={{ mr: "auto" }}>
          {logCount != null && pluralize(logCount, "log")}
        </Typography>
        <SortField request={request} setRequest={setRequest} />
        <Button
          color="primary"
          variant="contained"
          disableElevation
          startIcon={<Public />}
          onClick={onOpenMap}
        >
          Open Map
        </Button>
      </Stack>
    );
  }
}

function MapFilters({
  request,
  setRequest,
  logCount,
  results,
  onCloseMap,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
  logCount: Maybe<number>;
  results: React.ReactNode;
  onCloseMap: () => void;
}) {
  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: { name: request.name },
    onSubmit: setRequest,
  });

  const isMobile = useIsMobile();

  const mobileFilters = useMobileFilters();

  if (isMobile) {
    return (
      <>
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          <Tooltip title="Search results">
            <IconButton size="small" onClick={mobileFilters.toggle}>
              {mobileFilters.show ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <Typography>
            {logCount != null && pluralize(logCount, "log")}
          </Typography>
          <Button
            sx={{ ml: "auto" }}
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            onClick={onCloseMap}
          >
            Exit Map
          </Button>
        </Stack>
        <Collapse
          in={mobileFilters.show}
          sx={{
            overflowY: "auto",
            [`&:not(.${collapseClasses.hidden})`]: {
              mt: 1.5,
            },
          }}
        >
          <Stack spacing={1.5} sx={{ mt: 1 }}>
            <GroupsField request={request} setRequest={setRequest} />
            <SensorsField request={request} setRequest={setRequest} />
            <LabelsField request={request} setRequest={setRequest} />
            <Stack direction="row" spacing={1.5}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ width: "100%" }}
              >
                <LogNumberField control={control} />
              </Box>
              <SortField request={request} setRequest={setRequest} />
            </Stack>
          </Stack>
          {results}
        </Collapse>
      </>
    );
  } else {
    return (
      <>
        <Stack spacing={1.5}>
          <Stack direction="row" spacing={1.5}>
            <Box sx={{ minWidth: 0, flex: "1 0" }}>
              <GroupsField request={request} setRequest={setRequest} />
            </Box>
            <Box sx={{ minWidth: 0, flex: "1 0" }}>
              <SensorsField request={request} setRequest={setRequest} />
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={1.5}
            sx={{
              alignItems: "center",
            }}
          >
            <Box sx={{ minWidth: 0, flex: "1 0" }}>
              <LabelsField request={request} setRequest={setRequest} />
            </Box>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ width: "17ch" }}
            >
              <LogNumberField control={control} />
            </Box>
          </Stack>
          <Stack direction="row" spacing={1.5} sx={{ alignItems: "center" }}>
            <SortField request={request} setRequest={setRequest} />
            <Typography sx={{ mr: "auto" }}>
              {logCount != null && pluralize(logCount, "log")}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={onCloseMap}
            >
              Exit Map
            </Button>
          </Stack>
        </Stack>
        {results}
      </>
    );
  }
}

function PublicLogThumbnailsSection({
  request,
  setRequest,
  baseListRequest,
  processLabels,
  onOpenMap,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
  baseListRequest: ListLogsRequest;
  processLabels: LabelProcessor;
  onOpenMap: () => void;
}) {
  const thumbnailsQuery = useLogThumbnailsQuery(baseListRequest);

  return (
    <LogThumbnails
      logsQuery={thumbnailsQuery}
      processLabels={processLabels}
      filters={
        <ThumbnailsFilters
          request={request}
          setRequest={setRequest}
          logCount={thumbnailsQuery.data?.count}
          onOpenMap={onOpenMap}
        />
      }
      pagination={
        thumbnailsQuery.isSuccess && (
          <PaginationControls
            logCount={thumbnailsQuery.data.count}
            request={request}
            setRequest={setRequest}
          />
        )
      }
    />
  );
}

function PublicLogMapSection({
  request,
  setRequest,
  baseListRequest,
  processLabels,
  onCloseMap,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
  baseListRequest: ListLogsRequest;
  processLabels: LabelProcessor;
  onCloseMap: () => void;
}) {
  const mapQuery = useLogMapQuery(baseListRequest);

  return (
    <LogMap
      logsQuery={mapQuery}
      processLabels={processLabels}
      renderFilters={({ results }) => (
        <MapFilters
          request={request}
          setRequest={setRequest}
          logCount={mapQuery.data?.count}
          results={results}
          onCloseMap={onCloseMap}
        />
      )}
      pagination={
        mapQuery.isSuccess && (
          <PaginationControls
            disableJumping
            logCount={mapQuery.data.count}
            request={request}
            setRequest={setRequest}
          />
        )
      }
    />
  );
}

const MAX_VISIBLE_LABELS_MOBILE = 3;
const MAX_VISIBLE_NON_SENSOR_LABELS_NON_MOBILE = 5;

export function PublicDataStoreDashboardPage() {
  const [showMap, setShowMap] = useState(false);

  const [request, setRequest] = useLogsSearchRequest();

  const tagLabelIds = [...request.sensors, ...request.labelIds];

  const baseListRequest: ListLogsRequest = {
    nameLike: request.name,
    groupId: request.groupIds,
    tagLabelIdsIncludes: tagLabelIds,
    limit: request.limit,
    offset: request.offset,
    ...sortMapping[request.sort],
  };

  const isMobile = useIsMobile();

  function handleOpenMap(): void {
    setShowMap(true);
    setRequest({ offset: 0 });
  }

  function handleCloseMap(): void {
    setShowMap(false);
    setRequest({ offset: 0 });
  }

  const processLabels: LabelProcessor = (labels) => {
    const processedLabels = labels.map((label) => ({
      label,
      selected: tagLabelIds.some((labelId) => label.id === labelId),
      isSensor: SENSOR_LABEL_NAMES.has(label.value),
    }));

    processedLabels.sort((a, b) => {
      // 1. Sensor labels come before non-sensor labels
      if (a.isSensor && !b.isSensor) {
        return -1;
      } else if (!a.isSensor && b.isSensor) {
        return 1;
      }

      // 2. Labels are sorted in ascending alphabetical order
      return a.label.value.localeCompare(b.label.value);
    });

    let visible;
    let truncated;
    if (isMobile) {
      visible = processedLabels.slice(0, MAX_VISIBLE_LABELS_MOBILE);
      truncated = processedLabels.slice(visible.length);
    } else {
      // Since labels were sorted so all sensors come before all non-sensors,
      // the last index of a sensor label, if any, is also equivalent to
      // the number of sensors - 1
      const sensorsCount =
        processedLabels.findLastIndex(({ isSensor }) => isSensor) + 1;

      visible = processedLabels.slice(
        0,
        // On non-mobile screens, *all* sensor labels are shown while
        // non-sensor labels may be truncated
        sensorsCount + MAX_VISIBLE_NON_SENSOR_LABELS_NON_MOBILE,
      );
      truncated = processedLabels.slice(visible.length);
    }

    return {
      visible,
      truncated,
    };
  };

  return (
    <DataStoreDashboardPage
      showMap={showMap}
      thumbnailsSection={
        <PublicLogThumbnailsSection
          request={request}
          setRequest={setRequest}
          baseListRequest={baseListRequest}
          processLabels={processLabels}
          onOpenMap={handleOpenMap}
        />
      }
      mapSection={
        <PublicLogMapSection
          request={request}
          setRequest={setRequest}
          baseListRequest={baseListRequest}
          processLabels={processLabels}
          onCloseMap={handleCloseMap}
        />
      }
    />
  );
}
