import React, { useRef, useState } from "react";
import type { AutocompleteInputChangeReason } from "@mui/material";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  outlinedInputClasses,
  TextField,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { getFieldLabel } from "~/domain/common";
import type { BaseInputProps, Option } from "./types";

interface BaseComboBoxProps {
  optionsQuery: UseQueryResult<ReadonlyArray<Option>>;
  size?: "small" | "medium";
  noHelperText?: boolean;
}

interface MultiComboBoxProps
  extends BaseInputProps<Array<string>, Array<string>>,
    BaseComboBoxProps {}

interface SingleComboBoxProps
  extends BaseInputProps<string | null, string | null>,
    BaseComboBoxProps {}

type ComboBoxProps = MultiComboBoxProps | SingleComboBoxProps;

export function ComboBox({
  value,
  onChange,
  optionsQuery,
  name,
  errorMessage,
  label = getFieldLabel(name),
  required,
  size = "medium",
  noHelperText = false,
}: ComboBoxProps) {
  const multiple = Array.isArray(value);

  const [open, setOpen] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const helperText = noHelperText ? undefined : errorMessage ?? " ";

  function handleOpen(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleFocus(): void {
    inputRef.current?.parentElement?.scroll({
      left: inputRef.current.offsetLeft,
    });
  }

  function handleBlur(): void {
    inputRef.current?.parentElement?.scroll({ left: 0 });
  }

  function handleInputChange(
    e: React.SyntheticEvent | null,
    newInputValue: string,
    reason: AutocompleteInputChangeReason,
  ): void {
    if (multiple) {
      // MUI resets the input value when an option is (de)selected but doesn't
      // provide a way to disable this behavior:
      // https://github.com/mui/material-ui/issues/36085#issuecomment-1468024330
      //
      // Instead, need to detect when it's _likely_ happening (since they also
      // don't expose the underlying cause when `reason === "reset"`) and ignore
      // the event
      if (reason !== "reset") {
        setInputValue(newInputValue);
      } else if (e?.type === "blur" || e?.type === "change") {
        setInputValue(newInputValue);
      }
    } else {
      setInputValue(newInputValue);
    }
  }

  // TS doesn't handle the `newValue`'s type well so just cast it to `any`
  function handleChange(_: unknown, newValue: any): void {
    onChange(newValue);
  }

  const options = optionsQuery.data ?? [];

  const hasValue = multiple ? value.length > 0 : value !== null;

  const loading = optionsQuery.isFetching && (open || hasValue);

  function getOptionLabel(optionValue: string): string {
    return (
      options.find((option) => option.value === optionValue)?.label ??
      "Loading..."
    );
  }

  const commonProps = {
    fullWidth: true,
    autoHighlight: true,
    size,
    open,
    onOpen: handleOpen,
    onClose: handleClose,
    loading,
    onChange: handleChange,
    options: options.map((option) => option.value),
    getOptionLabel,
    inputValue,
    onInputChange: handleInputChange,
  };

  if (multiple) {
    return (
      <Autocomplete
        {...commonProps}
        multiple
        value={value}
        disableCloseOnSelect
        onFocus={handleFocus}
        onBlur={handleBlur}
        sx={{
          // Increase specificity so padding-right takes effect
          [`&&& .${autocompleteClasses.inputRoot}`]: {
            scrollBehavior: "smooth",
            pr: 0.75,
            overflowX: "auto",
            overflowY: "hidden",
            flexWrap: "nowrap",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            [`& .${autocompleteClasses.input}`]: {
              minWidth: 60,
            },
          },
          [`& .${autocompleteClasses.endAdornment}`]: {
            position: "static",
            display: "flex",
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            // Notched outline is a child of the input root but it shouldn't
            // scroll, so give it a fixed position relative to a containing block
            // that'll be rendered below
            position: "fixed",
          },
        }}
        renderInput={({
          disabled,
          fullWidth,
          size,
          id,
          InputLabelProps,
          InputProps,
          inputProps,
        }) => (
          // Need to wrap the outlined input in a containing block so the notched
          // outline can be positioned according to it. Can't just render a
          // typical text field since the form control would have to be the
          // notched outline's containing block which would break the styling when
          // helper text was present since it's also a direct child of the form
          // control
          <FormControl
            disabled={disabled}
            fullWidth={fullWidth}
            size={size}
            required={required}
            error={errorMessage !== undefined}
          >
            <InputLabel {...InputLabelProps}>{label}</InputLabel>
            <Box
              sx={{
                // Makes this a containing block for the fixed-position notched
                // outline
                willChange: "transform",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <OutlinedInput
                id={id}
                aria-describedby={`${id}-helper-text`}
                inputRef={inputRef}
                inputProps={inputProps}
                {...InputProps}
                label={label}
                endAdornment={
                  <>
                    <Box
                      sx={{
                        width: 20,
                        alignSelf: "stretch",
                        flex: "none",
                        position: "relative",
                      }}
                    >
                      {commonProps.loading && (
                        <CircularProgress
                          color="inherit"
                          size={18}
                          sx={{
                            position: "absolute",
                            inset: 0,
                            m: "auto",
                          }}
                        />
                      )}
                    </Box>
                    {InputProps.endAdornment}
                  </>
                }
              />
            </Box>
            {helperText != null && (
              <FormHelperText id={`${id}-helper-text`}>
                {helperText}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        {...commonProps}
        value={value}
        renderInput={(renderInputProps) => (
          <TextField
            {...renderInputProps}
            label={label}
            required={required}
            InputProps={{
              ...renderInputProps.InputProps,
              endAdornment: (
                <>
                  {commonProps.loading && (
                    <CircularProgress color="inherit" size={18} />
                  )}
                  {renderInputProps.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  }
}
