import { Link, TableCell, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import type { Column } from "~/components/Table";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  ResourceTable,
  withoutBaseTableModel,
} from "~/components/Table";
import { commonResourceColumns } from "../../../columns";
import { useCommonResourcesContextValue } from "../../../context";
import { renderForeignResource } from "../../../render-foreign-resource";
import type { CommonForeignResourceType, CommonUser } from "../../../types";
import { selectCountableListResponse } from "../../../utils";
import { listUsersSchema } from "../schemas";

function UserCell({ user }: { user: CommonUser }) {
  const { createUserDetailsLocation } = useCommonResourcesContextValue();

  return (
    <TableCell>
      {user.username}
      <Link
        component={RouterLink}
        to={createUserDetailsLocation({ userId: user.id })}
      >
        <Typography variant="body2">{user.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<Column<CommonUser, CommonForeignResourceType>> = [
  {
    header: "Username",
    sortKey: "username",
    renderCell(user) {
      return <UserCell user={user} />;
    },
  },
  {
    accessor: "roleId",
    dataType: "foreign-key",
    resourceType: "role",
  },
  {
    accessor: "admin",
    dataType: "boolean",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const [useUserSearchRequest, UserSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listUsersSchema,
  });

export function UserTable() {
  const { createUserListQueryOptions, userCreateLocation } =
    useCommonResourcesContextValue();

  const [request, setRequest] = useUserSearchRequest();

  const searchQuery = useQuery({
    ...createUserListQueryOptions(preprocessSearchRequest(request)),
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <ResourceTable
      resourceName="user"
      resourceCreateLocation={userCreateLocation}
      getRowKey={(user) => user.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
      renderForeignResource={renderForeignResource}
    />
  );
}
