import React from "react";
import {
  Album,
  Badge,
  Cloud,
  CloudDownload,
  CloudUpload,
  CloudUploadOutlined,
  Dashboard,
  Key,
  Label,
  LocalOffer,
  People,
  Person,
  Schema,
} from "@mui/icons-material";
import { Button, Divider, styled, svgIconClasses } from "@mui/material";
import { Database, FileCloud, FileMultiple } from "mdi-material-ui";
import type { Path } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  DocsLink,
  GlobalNavigation,
  NavigationLink,
  SignOutButton,
  useShouldUseMiniDrawerStyles,
} from "~/components/GlobalNavigation";
import { LqsLogo } from "~/components/lqs-logo";
import { AdminGuard } from "~/domain/common";
import { SupportTicketButton } from "~/dsm/support-ticket";
import * as paths from "~/paths";
import { useStudioLqsNavigator } from "./lqs-navigator";

export function StudioLqsNavigation() {
  const shouldUseMiniDrawerStyles = useShouldUseMiniDrawerStyles();

  const studioLqsNavigator = useStudioLqsNavigator();

  function renderLink(
    Icon: React.ComponentType<{ fontSize: "small" }>,
    text: string,
    path: string,
    to: Partial<Path>,
    end?: boolean,
  ) {
    return (
      <NavigationLink to={to} Icon={Icon} text={text} path={path} end={end} />
    );
  }

  return (
    <GlobalNavigation
      homepageLink={
        <RouterLink
          className="homepage-link"
          to={paths.makeStudioHomepageLocation()}
        >
          <LqsLogo alt="Homepage" icon={shouldUseMiniDrawerStyles} />
        </RouterLink>
      }
      footerActions={
        <>
          <Button
            component={RouterLink}
            to={paths.makeStudioHomepageLocation()}
            variant="contained"
            size="small"
          >
            Change DataStore
          </Button>
          <SupportTicketButton />
          <DocsLink />
          <SignOutButton />
        </>
      }
    >
      {renderLink(
        Dashboard,
        "Dashboard",
        studioLqsNavigator.DATASTORE_DASHBOARD,
        studioLqsNavigator.toDashboard(),
        true,
      )}
      {renderLink(
        CloudUpload,
        "Upload",
        studioLqsNavigator.UPLOAD,
        studioLqsNavigator.toUpload(),
      )}
      {renderLink(
        Album,
        "Player",
        studioLqsNavigator.PLAYER,
        studioLqsNavigator.toPlayer(),
      )}
      {renderLink(
        LocalOffer,
        "Tagging",
        studioLqsNavigator.TAGGING,
        studioLqsNavigator.toTagging(),
      )}
      <Divider />
      {renderLink(
        FileCloud,
        "Logs",
        studioLqsNavigator.LOG_TABLE,
        studioLqsNavigator.toLogTable(),
      )}
      {renderLink(
        CloudDownload,
        "Topics",
        studioLqsNavigator.TOPIC_TABLE,
        studioLqsNavigator.toTopicTable(),
      )}
      {renderLink(
        CloudUploadOutlined,
        "Ingestions",
        studioLqsNavigator.INGESTION_TABLE,
        studioLqsNavigator.toIngestionTable(),
      )}
      {renderLink(
        CloudDownload,
        "Digestions",
        studioLqsNavigator.DIGESTION_TABLE,
        studioLqsNavigator.toDigestionTable(),
      )}
      {renderLink(
        Schema,
        "Workflows",
        studioLqsNavigator.WORKFLOW_TABLE,
        studioLqsNavigator.toWorkflowTable(),
      )}
      {renderLink(
        Label,
        "Labels",
        studioLqsNavigator.LABEL_TABLE,
        studioLqsNavigator.toLabelTable(),
      )}
      {renderLink(
        Database,
        "Object Stores",
        studioLqsNavigator.OBJECT_STORE_TABLE,
        studioLqsNavigator.toObjectStoreTable(),
      )}
      <Divider />
      {renderLink(
        Person,
        "Profile",
        studioLqsNavigator.PROFILE,
        studioLqsNavigator.toProfile(),
      )}
      <AdminGuard>
        {renderLink(
          People,
          "Users",
          studioLqsNavigator.USER_TABLE,
          studioLqsNavigator.toUserTable(),
        )}
        {renderLink(
          Group,
          "Groups",
          studioLqsNavigator.GROUP_TABLE,
          studioLqsNavigator.toGroupTable(),
        )}
        {renderLink(
          Badge,
          "Roles",
          studioLqsNavigator.ROLE_TABLE,
          studioLqsNavigator.toRoleTable(),
        )}
        {renderLink(
          Key,
          "API Keys",
          studioLqsNavigator.API_KEY_TABLE,
          studioLqsNavigator.toApiKeyTable(),
        )}
      </AdminGuard>
    </GlobalNavigation>
  );
}

const GroupIconRoot = styled("div")(({ theme }) => ({
  position: "relative",
  [`& .${svgIconClasses.root}`]: {
    // First icon is <FileMultiple />. <GroupIconRoot /> will take on its size
    "&:first-of-type": {
      // Ignores line-height which (I think) caused <GroupIconRoot /> to have
      // a taller height than expected
      display: "block",
    },
    // Second icon is <Cloud />. It is positioned over <FileMultiple />
    "&:last-of-type": {
      position: "absolute",
      left: "50%",
      top: "50%",
      translate: "calc(-50% + 1.5px) calc(-50% + 1.5px)",
      scale: "50%",
      color: theme.palette.background.paper,
    },
  },
}));

// The group resource represents a collection of logs, not a collection of
// users. I couldn't find an appropriate icon so this stacks a cloud icon on top
// of a multi-file icon to look like a stack of the FileCloud icon used for logs
function Group({ fontSize }: { fontSize: "small" }) {
  return (
    <GroupIconRoot>
      <FileMultiple fontSize={fontSize} />
      <Cloud fontSize={fontSize} />
    </GroupIconRoot>
  );
}
