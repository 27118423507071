import { Amplify } from "aws-amplify";
import { Configuration as DsmConfiguration } from "~/dsm";
import { getAuthToken } from "~/lib/auth";
import { Configuration as LqsConfiguration } from "~/lqs";
import {
  buildDataStoreEndpoint,
  buildDsmEndpoint,
} from "~/utils/build-api-endpoints";

const API_ENDPOINT = "https://api.logqs.com";

const DATA_STORE_ID = "a37c7445-ebba-4108-93a6-29ff2cd55df3";

const USER_POOL_ID = "us-east-1_IQ2AyGJcr";
const USER_POOL_WEB_CLIENT_ID = "3ec3137lrskccci185sckpc0v0";

const MAP_NAME = "logqs";
const MAP_IDENTITY_POOL_ID = "us-east-1:1abd9bf7-a5e2-4053-b76b-8d5941966dba";
const MAP_IDENTITY_POOL_REGION = MAP_IDENTITY_POOL_ID.split(":")[0];

const OAUTH_DOMAIN = "logqs-global.auth.us-east-1.amazoncognito.com";
const OAUTH_SCOPES = ["email", "openid", "phone"];

export function configureApp(): void {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: USER_POOL_ID,
        userPoolClientId: USER_POOL_WEB_CLIENT_ID,
        identityPoolId: MAP_IDENTITY_POOL_ID,
        allowGuestAccess: true,
        signUpVerificationMethod: "code",
        loginWith: {
          oauth: {
            domain: OAUTH_DOMAIN,
            scopes: OAUTH_SCOPES,
            redirectSignIn: [window.location.origin],
            redirectSignOut: [window.location.origin],
            responseType: "code",
          },
        },
      },
    },
    Geo: {
      LocationService: {
        maps: {
          items: {
            [MAP_NAME]: {
              style: "RasterEsriImagery",
            },
          },
          default: MAP_NAME,
        },
        region: MAP_IDENTITY_POOL_REGION,
      },
    },
  });
}

export function createDsmSdkConfiguration(): DsmConfiguration {
  const basePath = buildDsmEndpoint(API_ENDPOINT);

  return new DsmConfiguration({
    basePath,
    // If the user isn't signed in then trying to get the token should reject
    // and cause the request to fail. The <AuthenticatedGuard /> component
    // should be used though to make sure components making requests are only
    // mounted when the user is signed in.
    accessToken: getAuthToken,
  });
}

export function createLqsSdkConfiguration(): LqsConfiguration {
  const basePath = buildDataStoreEndpoint(API_ENDPOINT, DATA_STORE_ID);

  return new LqsConfiguration({
    basePath,
    // SDK types require the promise resolves to a string but the SDK won't set
    // the auth header if given the empty string. It's fine to essentially
    // ignore errors here because either the user isn't authenticated in which
    // case we won't attach a header or their token expired in which case
    // Amplify will forcefully redirect to the hosted UI.
    accessToken: () => getAuthToken().catch(() => ""),
  });
}

const INTRO_VIDEO_VERSION = 2;
export const INTRO_VIDEO_PATH = `/static/media/videos/logqs-intro-v2.${INTRO_VIDEO_VERSION}.mp4`;
export const INTRO_VIDEO_THUMBNAIL_PATH =
  "/static/media/images/logqs-intro-v2.0.thumbnail.png";
