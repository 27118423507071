import React from "react";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import * as z from "zod";
import {
  CheckboxInput,
  NumberInput,
  ObjectInput,
  SelectInput,
  TextInput,
  useField,
} from "~/components/Form";
import type { BaseFieldProps, Option } from "~/components/Form/types";
import { getFieldLabel } from "~/domain/common";
import type { Workflow } from "~/lqs";
import { useWorkflowQueryOptionsFactory } from "~/lqs";
import { assertNever } from "~/utils";

export function WorkflowContextField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, object | null>,
>({
  workflowId,
  control,
  ...rest
}: BaseFieldProps<TFieldValues, TName> & {
  workflowId: Workflow["id"] | null;
}) {
  const { name } = rest;
  const workflowContextField = useField({
    control,
    name,
    // The digestion sidebar is expected to set the `workflowId` as this
    // component's `key` so React will remount when the ID changes. By
    // telling RHF to unregister the field on unmount, any non-default value
    // in form state for the previous workflow ID will be removed.
    shouldUnregister: true,
  });

  const createWorkflowQueryOptions = useWorkflowQueryOptionsFactory();
  const contextSchemaQuery = useQuery({
    ...createWorkflowQueryOptions(workflowId),
    select({ data: { contextSchema } }) {
      if (contextSchema === null) {
        return {
          status: "no-schema",
        } as const;
      } else {
        const parseResult = workflowContextJsonSchema.safeParse(contextSchema);

        if (parseResult.success) {
          return {
            status: "supported",
            descriptions: parseResult.data,
          } as const;
        } else {
          return {
            status: "unsupported",
          } as const;
        }
      }
    },
  });

  if (workflowId === null) {
    return <Typography>Select a workflow to supply context</Typography>;
  } else if (contextSchemaQuery.isLoading) {
    return <Typography>Loading context schema...</Typography>;
  } else if (
    // Still let the user pass workflow context if some error prevented
    // the context schema from being fetched. It'll be at their own risk.
    contextSchemaQuery.isError ||
    // Studio isn't expected to support every possible context schema. In such
    // a case, the user can still pass a workflow context but they'll have to
    // use the raw JSON input field.
    contextSchemaQuery.data.status === "unsupported"
  ) {
    return (
      <ObjectInput
        {...rest}
        {...workflowContextField}
        helperText={
          contextSchemaQuery.isError
            ? "Unable to load context schema"
            : contextSchemaQuery.data.status === "unsupported"
              ? "Structured entry not supported for this workflow's context schema"
              : undefined
        }
      />
    );
  } else if (contextSchemaQuery.data.status === "no-schema") {
    return <Typography>This workflow has no context schema</Typography>;
  } else {
    const {
      data: { descriptions: subFieldDescriptions },
    } = contextSchemaQuery;

    // Since the subfield descriptions are derived from the workflow ID which
    // is kept in form state, it's not really possible to store their default
    // values in RHF itself so they instead need to be derived here.
    const fieldValue =
      workflowContextField.value ??
      getDefaultWorkflowContext(subFieldDescriptions);

    return (
      <Stack spacing={2} useFlexGap>
        <Typography sx={{ color: "text.secondary" }}>
          Workflow Context
        </Typography>
        {subFieldDescriptions.map((subFieldDescription) => {
          const { name: subFieldName, required } = subFieldDescription;

          const subFieldLabel =
            subFieldDescription.label ?? getFieldLabel(subFieldName);

          const subFieldValue = fieldValue[subFieldName];

          // Subfield changes are merged in to the overall workflow context.
          function handleSubFieldChange(newValue: unknown): void {
            workflowContextField.onChange({
              ...fieldValue,
              [subFieldName]: newValue,
            });
          }

          let content;
          switch (subFieldDescription.type) {
            case "string": {
              content = (
                <TextInput
                  name={subFieldName}
                  label={subFieldLabel}
                  required={required}
                  value={subFieldValue as string | null}
                  onChange={handleSubFieldChange}
                />
              );
              break;
            }
            case "number":
            case "integer": {
              content = (
                <NumberInput
                  name={subFieldName}
                  label={subFieldLabel}
                  required={required}
                  value={subFieldValue as number | null}
                  onChange={handleSubFieldChange}
                />
              );
              break;
            }
            case "boolean": {
              content = (
                <CheckboxInput
                  name={subFieldName}
                  label={subFieldLabel}
                  required={required}
                  value={subFieldValue as boolean | null}
                  onChange={handleSubFieldChange}
                />
              );
              break;
            }
            case "enum": {
              content = (
                <SelectInput
                  name={subFieldName}
                  label={subFieldLabel}
                  required={required}
                  options={subFieldDescription.options}
                  value={subFieldValue as string | null}
                  onChange={handleSubFieldChange}
                />
              );
              break;
            }
            default: {
              assertNever(subFieldDescription);
            }
          }

          return <React.Fragment key={subFieldName}>{content}</React.Fragment>;
        })}
      </Stack>
    );
  }
}

interface StringFieldDescription {
  name: string;
  type: "string";
  label?: string;
  default?: string;
  required: boolean;
}

interface NumberFieldDescription {
  name: string;
  type: "number" | "integer";
  label?: string;
  default?: number;
  required: boolean;
}

interface BooleanFieldDescription {
  name: string;
  type: "boolean";
  label?: string;
  default?: boolean;
  required: boolean;
}

interface EnumFieldDescription {
  name: string;
  type: "enum";
  label?: string;
  default?: string;
  required: boolean;
  options: ReadonlyArray<Option>;
}

type WorkflowContextFieldDescription =
  | StringFieldDescription
  | NumberFieldDescription
  | BooleanFieldDescription
  | EnumFieldDescription;

const workflowContextField = z.union([
  z.object({
    type: z.literal("string"),
    title: z.string().optional(),
    default: z.string().optional(),
  }),
  z.object({
    type: z.union([z.literal("number"), z.literal("integer")]),
    title: z.string().optional(),
    default: z.number().optional(),
  }),
  z.object({
    type: z.literal("boolean"),
    title: z.string().optional(),
    default: z.boolean().optional(),
  }),
  z.object({
    oneOf: z.array(
      z.object({
        const: z.string(),
        title: z.string(),
      }),
    ),
    title: z.string().optional(),
    default: z.string().optional(),
  }),
]);

const workflowContextJsonSchema = z
  .object({
    type: z.literal("object"),
    properties: z.record(workflowContextField),
    required: z.array(z.string()).optional(),
  })
  .transform((value): ReadonlyArray<WorkflowContextFieldDescription> => {
    return Object.entries(value.properties).map(([name, schema]) => {
      const required = value.required?.includes(name) ?? false;

      if ("oneOf" in schema) {
        return {
          name,
          type: "enum",
          label: schema.title,
          default: schema.default,
          required,
          options: schema.oneOf.map((enumOptionSchema) => ({
            label: enumOptionSchema.title,
            value: enumOptionSchema.const,
          })),
        };
      } else {
        return {
          name,
          type: schema.type,
          label: schema.title,
          default: schema.default,
          required,
        } as WorkflowContextFieldDescription;
      }
    });
  });

function getDefaultWorkflowContext(
  subFieldDescriptions: ReadonlyArray<WorkflowContextFieldDescription>,
): Record<string, unknown> {
  const workflowContext: Record<string, unknown> = {};

  subFieldDescriptions.forEach((subFieldDescription) => {
    workflowContext[subFieldDescription.name] =
      subFieldDescription.default ?? null;
  });

  return workflowContext;
}
