import type React from "react";
import type { Draft } from "immer";
import type { ImmerReducer } from "use-immer";
import { useImmerReducer } from "use-immer";
import { invariant } from "~/lib/invariant";
import {
  filter,
  findIndex,
  isEqual,
  map,
  maxBy,
  minBy,
  pullAllWith,
  pullAt,
} from "~/lib/std";
import type { Topic } from "~/lqs";
import type { LayoutNode, PlaybackSource } from "~/player";
import { iteratePanels } from "~/player";
import type { TimeRange } from "~/types";
import { assertNever } from "~/utils";
import type { DraftDigestionTopic } from "../../types";

type DigestionSidebarView = "edit" | "finalize" | "list";

interface SetSelectedTopicsAction {
  type: "set-selected-topics";
  payload: {
    topics: ReadonlyArray<Topic>;
  };
}

export function setSelectedTopics(
  payload: SetSelectedTopicsAction["payload"],
): SetSelectedTopicsAction {
  return {
    type: "set-selected-topics",
    payload,
  };
}

interface SelectLayoutTopicsAction {
  type: "select-layout-topics";
}

export function selectLayoutTopics(): SelectLayoutTopicsAction {
  return {
    type: "select-layout-topics",
  };
}

interface DraftSelectedTopicsAction {
  type: "draft-selected-topics";
}

export function draftSelectedTopics(): DraftSelectedTopicsAction {
  return {
    type: "draft-selected-topics",
  };
}

interface DraftTopicAction {
  type: "draft-topic";
  payload: {
    topic: Topic;
    range: TimeRange;
  };
}

export function draftTopic(
  payload: DraftTopicAction["payload"],
): DraftTopicAction {
  return {
    type: "draft-topic",
    payload,
  };
}

interface RemoveDraftTopicAction {
  type: "remove-draft-topic";
  payload: {
    topic: DraftDigestionTopic;
  };
}

export function removeDraftTopic(
  payload: RemoveDraftTopicAction["payload"],
): RemoveDraftTopicAction {
  return {
    type: "remove-draft-topic",
    payload,
  };
}

interface StartFinalizingAction {
  type: "start-finalizing";
}

export function startFinalizing(): StartFinalizingAction {
  return {
    type: "start-finalizing",
  };
}

interface ReturnToEditingAction {
  type: "return-to-editing";
}

export function returnToEditing(): ReturnToEditingAction {
  return {
    type: "return-to-editing",
  };
}

interface ResetDraftAction {
  type: "reset-draft";
}

export function resetDraft(): ResetDraftAction {
  return {
    type: "reset-draft",
  };
}

interface ListDigestionsAction {
  type: "list-digestions";
}

export function listDigestions(): ListDigestionsAction {
  return {
    type: "list-digestions",
  };
}

interface ShowNewDigestionAction {
  type: "show-new-digestion";
}

export function showNewDigestion(): ShowNewDigestionAction {
  return {
    type: "show-new-digestion",
  };
}

export type DraftDigestionActions =
  | SetSelectedTopicsAction
  | SelectLayoutTopicsAction
  | DraftSelectedTopicsAction
  | DraftTopicAction
  | RemoveDraftTopicAction
  | StartFinalizingAction
  | ReturnToEditingAction
  | ResetDraftAction
  | ListDigestionsAction
  | ShowNewDigestionAction;

interface DraftDigestionReducerState {
  view: DigestionSidebarView;
  topics: Array<DraftDigestionTopic>;
  selectedTopicIds: Array<Topic["id"]>;
}

export interface DraftDigestion extends DraftDigestionReducerState {
  canSelectLayoutTopics: boolean;
  dispatch: React.Dispatch<DraftDigestionActions>;
}

const initialState: Readonly<DraftDigestionReducerState> = {
  view: "edit",
  topics: [],
  selectedTopicIds: [],
};

export interface UseDraftDigestionArgs {
  playerTopics: Array<Topic> | undefined;
  playerRange: PlaybackSource["range"];
  layout: LayoutNode;
}

export function useDraftDigestion({
  playerTopics,
  playerRange,
  layout,
}: UseDraftDigestionArgs): DraftDigestion {
  const layoutTopicNames = new Array<Topic["name"]>();
  iteratePanels(layout, (panel) => {
    if (panel.isInitialized) {
      layoutTopicNames.push(panel.topicName);
    }
  });

  const [draftDigestionState, dispatch] = useImmerReducer(
    createReducer({
      playerTopics,
      playerRange,
      layoutTopicNames,
    }),
    initialState,
  );

  return {
    ...draftDigestionState,
    canSelectLayoutTopics: layoutTopicNames.length > 0,
    dispatch,
  };
}

function createReducer({
  playerTopics,
  playerRange,
  layoutTopicNames,
}: Pick<UseDraftDigestionArgs, "playerTopics" | "playerRange"> & {
  layoutTopicNames: Array<Topic["name"]>;
}): ImmerReducer<DraftDigestionReducerState, DraftDigestionActions> {
  return function reducer(draftState, action) {
    invariant(
      playerTopics !== undefined && playerRange !== undefined,
      "Topics and/or range not defined",
    );

    switch (action.type) {
      case "set-selected-topics": {
        draftState.selectedTopicIds = map(action.payload.topics, "id");

        return;
      }
      case "select-layout-topics": {
        invariant(layoutTopicNames.length > 0, "No layout topics to select");

        const layoutTopics = playerTopics.filter(({ name }) =>
          layoutTopicNames.includes(name),
        );

        draftState.selectedTopicIds = map(layoutTopics, "id");

        return;
      }
      case "draft-selected-topics": {
        invariant(
          draftState.selectedTopicIds.length > 0,
          "Must have at least one topic selected",
        );

        for (const topicId of draftState.selectedTopicIds) {
          addTopicToDraft(draftState, topicId, playerRange);
        }

        return;
      }
      case "draft-topic": {
        addTopicToDraft(
          draftState,
          action.payload.topic.id,
          action.payload.range,
        );

        return;
      }
      case "remove-draft-topic": {
        const topicIndex = findIndex(draftState.topics, action.payload.topic);

        invariant(topicIndex !== -1, "Topic not found");

        pullAt(draftState.topics, topicIndex);

        return;
      }
      case "start-finalizing": {
        invariant(
          draftState.topics.length > 0,
          "Must have at least one topic drafted to finalize",
        );
        invariant(draftState.view !== "finalize", "Already finalizing");

        draftState.view = "finalize";

        return;
      }
      case "return-to-editing": {
        invariant(draftState.view !== "edit", "Already editing");

        draftState.view = "edit";

        return;
      }
      case "reset-draft": {
        return initialState;
      }
      case "list-digestions": {
        invariant(draftState.view !== "list", "Already listing digestions");

        draftState.view = "list";

        return;
      }
      case "show-new-digestion": {
        return {
          ...initialState,
          view: "list",
        };
      }
      default: {
        assertNever(action);
      }
    }
  };
}

function addTopicToDraft(
  draftState: Draft<DraftDigestionReducerState>,
  topicId: Topic["id"],
  playerBounds: NonNullable<PlaybackSource["bounds"]>,
): void {
  const newDraftTopic: DraftDigestionTopic = {
    topicId,
    ...playerBounds,
  };

  // If the new draft topic's time range overlaps with any existing
  // draft topics with a matching topic ID, those need to be merged
  // into a single draft
  const overlappingTopics = filter(draftState.topics, (topic) => {
    if (topic.topicId !== topicId) {
      return false;
    }

    return areTimeRangesOverlapping(topic, newDraftTopic);
  });

  if (overlappingTopics.length === 0) {
    // New draft didn't overlap with anything so just push it
    // and continue
    draftState.topics.push(newDraftTopic);

    return;
  }

  // New draft topic and all drafts it overlapped with need to be
  // merged into a single draft whose time range spans them all.
  const { startTime: minOverlapTime } = minBy(
    [...overlappingTopics, newDraftTopic],
    "startTime",
  )!;
  const { endTime: maxOverlapTime } = maxBy(
    [...overlappingTopics, newDraftTopic],
    "endTime",
  )!;

  // Remove existing overlapped drafts which will be represented by
  // the merged draft
  pullAllWith(draftState.topics, overlappingTopics, isEqual);

  // Push the merged draft in place of new draft and overlapped drafts
  draftState.topics.push({
    topicId,
    startTime: minOverlapTime,
    endTime: maxOverlapTime,
  });
}

function areTimeRangesOverlapping(x: TimeRange, y: TimeRange): boolean {
  return y.startTime <= x.endTime && x.startTime <= y.endTime;
}
