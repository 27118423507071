import React, { useLayoutEffect, useRef, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import type { ButtonBaseActions, TooltipProps } from "@mui/material";
import {
  alpha,
  CardActionArea,
  Chip,
  getOverlayAlpha,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import prettyMilliseconds from "pretty-ms";
import { Link as RouterLink } from "react-router-dom";
import { renderQuery } from "~/components/QueryRenderer";
import { LogThumbnail, useAllLabels } from "~/domain/logs";
import { useFormatToDay } from "~/format";
import { useIsMobile } from "~/layout";
import { nanosecondsToMilliseconds } from "~/lib/dates";
import { emplace, floor } from "~/lib/std";
import type { Label, LabelListResponse, Log } from "~/lqs";
import { useGroup, useTags } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { assertNever, combineQueries } from "~/utils";

export interface ProcessedLabel {
  label: Label;
  selected: boolean;
}

export type LabelProcessor = (appliedLabels: ReadonlyArray<Label>) => {
  visible: ReadonlyArray<ProcessedLabel>;
  truncated: ReadonlyArray<ProcessedLabel>;
};

// "Ultra-wide" breakpoint. If more features end up needing this breakpoint
// it should probably go into the theme.
const UW = 2000;

const GalleryRoot = styled("div")(({ theme }) => ({
  marginBlockEnd: theme.spacing(3),
  display: "grid",
  gridTemplateColumns: "repeat(var(--gallery-cols), minmax(0, 1fr))",
  gap: theme.spacing(2),
  [theme.breakpoints.up(UW)]: {
    "--gallery-cols": 4,
  },
  [theme.breakpoints.down(UW)]: {
    "--gallery-cols": 3,
  },
  [theme.breakpoints.down("lg")]: {
    "--gallery-cols": 2,
  },
  [theme.breakpoints.down("md")]: {
    "--gallery-cols": 1,
  },
}));

export function LogGallery({
  logs,
  processLabels,
}: {
  logs: ReadonlyArray<Log>;
  processLabels: LabelProcessor;
}) {
  const formatToDay = useFormatToDay();

  const groupedLogs = groupLogsByDay(logs, formatToDay);

  return groupedLogs.map(({ day, logs }) => (
    <Stack key={day}>
      <Typography variant="h6" component="p" gutterBottom>
        {day}
      </Typography>
      <GalleryRoot>
        {logs.map((log) => (
          <LogGalleryItem
            key={log.id}
            log={log}
            processLabels={processLabels}
          />
        ))}
      </GalleryRoot>
    </Stack>
  ));
}

function groupLogsByDay(
  logs: ReadonlyArray<Log>,
  formatToDay: (value: Date | bigint) => string,
): ReadonlyArray<{ day: string; logs: ReadonlyArray<Log> }> {
  const groupedLogsMap = new Map<string, ReadonlyArray<Log>>();

  for (const log of logs) {
    emplace(groupedLogsMap, formatToDay(log.startTime!), {
      insert: () => [log],
      update: (value) => value.concat(log),
    });
  }

  const groupedLogs = new Array<{ day: string; logs: ReadonlyArray<Log> }>();
  for (const [day, dayGroup] of groupedLogsMap) {
    groupedLogs.push({ day, logs: dayGroup });
  }

  return groupedLogs;
}

const GalleryItemRoot = styled(CardActionArea)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "stretch",
  gap: theme.spacing(1),
})) as typeof CardActionArea;

const LogAttributes = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: theme.spacing(1),
  right: theme.spacing(1),
  height: "200px",
  display: "grid",
  gridTemplateAreas: `
    "group duration"
    "name  ."
  `,
  placeContent: "space-between",
  gap: theme.spacing(2),
}));

const LogGroup = styled(Typography)(({ theme }) => ({
  gridArea: "group",
  placeSelf: "start start",
  maxWidth: "100%",
  padding: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.background.paper, 0.75),
  backdropFilter: "blur(10px)",
}));

const LogDuration = styled(Typography)(({ theme }) => ({
  gridArea: "duration",
  placeSelf: "start end",
  maxWidth: "100%",
  fontWeight: "bold",
  padding: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.background.paper, 0.75),
  backdropFilter: "blur(10px)",
}));

const LogName = styled(Typography)(({ theme }) => ({
  gridArea: "name",
  placeSelf: "end start",
  maxWidth: "100%",
  fontWeight: "bold",
  padding: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.background.paper, 0.75),
  backdropFilter: "blur(10px)",
}));

export function LogGalleryItem({
  log,
  processLabels,
  actionRef,
}: {
  log: Log;
  processLabels: LabelProcessor;
  actionRef?: React.Ref<ButtonBaseActions>;
}) {
  const [focused, setFocused] = useState(false);
  const focusTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  function handleFocus(): void {
    focusTimeoutRef.current = setTimeout(() => {
      setFocused(true);
    }, 250);
  }

  function handleBlur(): void {
    if (focusTimeoutRef.current !== null) {
      clearTimeout(focusTimeoutRef.current);
      focusTimeoutRef.current = null;
    }

    setFocused(false);
  }

  const groupNameQuery = useGroup(log.groupId, {
    select(response) {
      return response.data.name;
    },
  });

  const lqsNavigator = useLqsNavigator();

  return (
    <GalleryItemRoot
      action={actionRef}
      component={RouterLink}
      to={lqsNavigator.toPlayer({ logId: log.id })}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <LogThumbnail log={log} height={200} width="100%" />
      <TagList log={log} focused={focused} processLabels={processLabels} />
      <LogAttributes>
        <LogGroup
          variant="body2"
          noWrap
          style={
            groupNameQuery.status === "success"
              ? { fontWeight: "bold" }
              : { fontStyle: "italic" }
          }
        >
          {renderQuery(groupNameQuery, {
            loading: "Loading...",
            error: "Unknown",
            success: (name) => name,
          })}
        </LogGroup>
        <LogDuration variant="body2" noWrap>
          {prettyMilliseconds(
            floor(nanosecondsToMilliseconds(log.endTime! - log.startTime!), 1),
            {
              colonNotation: true,
              keepDecimalsOnWholeSeconds: true,
            },
          )}
        </LogDuration>
        <LogName variant="body2" noWrap>
          {log.name}
        </LogName>
      </LogAttributes>
    </GalleryItemRoot>
  );
}

const colorStop = alpha("#FFF", Number(getOverlayAlpha(1)));

const TruncatedChipsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    maxWidth: 400,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: alpha(theme.palette.background.paper, 0.75),
    backdropFilter: "blur(10px)",
    // MUI applies a transparent background image over <Paper />'s background color
    // in dark mode which needs to be manually recreated here:
    // https://github.com/mui/material-ui/blob/ae2fc45a686ad0ac71ac3ab47a96d74175ce6525/packages/mui-material/src/Paper/Paper.js#L56-L60
    ...(theme.palette.mode === "dark" && {
      backgroundImage: `linear-gradient(${colorStop}, ${colorStop})`,
    }),
  },
}));

function TagList({
  log,
  processLabels,
  focused,
}: {
  log: Log;
  processLabels: LabelProcessor;
  focused: boolean;
}) {
  const [showTruncated, setShowTruncated] = useState(false);

  function handlePointerEnter(): void {
    setShowTruncated(true);
  }

  function handlePointerLeave(): void {
    setShowTruncated(false);
  }

  function handleMobileChipClick(e: React.MouseEvent<HTMLElement>): void {
    // The chip is a child of an <a> so prevent the default navigation behavior
    e.preventDefault();

    setShowTruncated((prev) => !prev);
  }

  const allLabelsQuery = useAllLabels({ select: selectLabelMap });

  const tagsQuery = useTags(log.id, { limit: 500 });

  const appliedLabelsQuery = combineQueries({
    queries: [allLabelsQuery, tagsQuery],
    transform([labelMap, { data: tags }]) {
      // For the gallery, want to show every label applied to this log whether
      // it has timestamps or not. Each label should only get listed once though.
      const appliedLabels = new Array<Label>();
      const uniqueLabelIds = new Set(tags.map((tag) => tag.labelId));
      for (const labelId of uniqueLabelIds) {
        const label = labelMap.get(labelId);
        if (label === undefined) {
          // Shouldn't happen
          continue;
        }

        appliedLabels.push(label);
      }

      return processLabels(appliedLabels);
    },
  });

  const isMobile = useIsMobile();

  const [prevIsMobile, setPrevIsMobile] = useState(isMobile);
  // Can't set state during render because there's a bug with React's
  // `useSyncExternalStore` (which MUI is using for the media query) and dispatching
  // state during render, so update in a layout effect instead for now.
  useLayoutEffect(
    function resetOnMobileChange() {
      if (isMobile !== prevIsMobile) {
        setPrevIsMobile(isMobile);
        setShowTruncated(false);
      }
    },
    [isMobile, prevIsMobile],
  );

  let content;
  switch (appliedLabelsQuery.status) {
    case "loading": {
      content = [1, 2, 3].map((key) => (
        <Skeleton
          key={key}
          variant="rectangular"
          width="8ch"
          height={32}
          sx={{ borderRadius: 4 }}
        />
      ));

      break;
    }
    case "error": {
      content = null;

      break;
    }
    case "success": {
      const {
        data: { visible, truncated },
      } = appliedLabelsQuery;

      const visibleChips = visible.map(({ label: { id, value }, selected }) => (
        <Chip key={id} label={value} color={selected ? "primary" : undefined} />
      ));
      const truncatedChips = truncated.map(
        ({ label: { id, value }, selected }) => (
          <Chip
            key={id}
            label={value}
            color={selected ? "primary" : undefined}
          />
        ),
      );

      content = (
        <>
          {visibleChips}
          {truncatedChips.length > 0 &&
            (isMobile ? (
              <>
                {showTruncated && truncatedChips}
                <Chip
                  variant="outlined"
                  icon={showTruncated ? <ExpandLess /> : <ExpandMore />}
                  label={showTruncated ? "Hide" : `+${truncatedChips.length}`}
                  onClick={handleMobileChipClick}
                />
              </>
            ) : (
              <TruncatedChipsTooltip
                open={focused || showTruncated}
                title={truncatedChips}
              >
                <Typography
                  onPointerEnter={handlePointerEnter}
                  onPointerLeave={handlePointerLeave}
                  sx={{ fontWeight: "bold" }}
                >
                  +{truncatedChips.length}
                </Typography>
              </TruncatedChipsTooltip>
            ))}
        </>
      );

      break;
    }
    default: {
      assertNever(appliedLabelsQuery);
    }
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        // The list of chips shouldn't contribute to the width of the gallery
        // item. Instead, once the gallery item's width is determined, the chips
        // should fill whatever space is available and wrap as needed. Setting
        // `contain: inline-size` prevents the chips from being used for
        // determining the overall gallery item's width.
        // TODO: Is this still necessary since we're using strict grid columns?
        contain: "inline-size",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {content}
    </Stack>
  );
}

function selectLabelMap(response: LabelListResponse): Map<Label["id"], Label> {
  return new Map(response.data.map((label) => [label.id, label]));
}
