import { useMediaQuery } from "@mui/material";

const MIN = 0;
const MAX = 750;

const portraitMediaQuery = `(orientation: portrait) and (${MIN}px <= width <= ${MAX}px)`;
const landscapeMediaQuery = `(orientation: landscape) and (${MIN}px <= height <= ${MAX}px)`;

const mobileMediaQuery = [portraitMediaQuery, landscapeMediaQuery].join(", ");

export function useIsMobile(): boolean {
  return useMediaQuery(mobileMediaQuery, { noSsr: true });
}
