import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import {
  CheckboxField,
  Form,
  FormSkeleton,
  getChangedFields,
  useStudioForm,
} from "~/components/Form";
import { QueryRenderer } from "~/components/QueryRenderer";
import { pick } from "~/lib/std";
import { selectData } from "~/utils";
import { useCommonResourcesContextValue } from "../../../context";
import { renderForeignResource } from "../../../render-foreign-resource";
import type { CommonUser } from "../../../types";
import { useUserParams } from "../hooks";
import { editUserSchema } from "../schemas";

export function UserDetails() {
  const { userId } = useUserParams();

  const {
    createUserFetchQueryOptions,
    createUserDeleteMutationOptions,
    createUserEditLocation,
    userTableLocation,
  } = useCommonResourcesContextValue();

  const query = useQuery({
    ...createUserFetchQueryOptions(userId),
    select: selectData,
  });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = (
    <HistoryCard query={query} renderForeignResource={renderForeignResource} />
  );
  const manageSection = (
    <ManageCard
      resourceName="user"
      query={query}
      editLocation={createUserEditLocation({ userId })}
      deleteMutation={useMutation(createUserDeleteMutationOptions(userId))}
      getReadableName={(user) => user.username}
      listLocation={userTableLocation}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<CommonUser> }) {
  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "username" },
          { dataType: "foreign-key", resourceType: "role", accessor: "roleId" },
          { dataType: "boolean", accessor: "managed" },
        ]}
        renderForeignResource={renderForeignResource}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<CommonUser> }) {
  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={<FormSkeleton shapes={["text", "text"]} />}
        success={(user) => <InfoSectionImpl user={user} />}
      />
    </Card>
  );
}

function InfoSectionImpl({ user }: { user: CommonUser }) {
  const { createUserUpdateMutationOptions } = useCommonResourcesContextValue();

  const updateUser = useMutation(createUserUpdateMutationOptions(user.id));

  const { enqueueSnackbar } = useSnackbar();

  const schema = editUserSchema.pick({ admin: true, disabled: true });
  const FIELDS = schema.keyof().options;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useStudioForm({
    schema,
    defaultValues: pick(user, FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateUser.mutate(changedFields, {
        onSuccess(response) {
          enqueueSnackbar("User updated", { variant: "success" });

          reset(pick(response.data, FIELDS));
        },
        onError() {
          enqueueSnackbar("Unable to update user", { variant: "error" });
        },
      });
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={updateUser.isLoading}
      submitText="Save Changes"
    >
      <CheckboxField control={control} name="admin" />
      <CheckboxField control={control} name="disabled" />
    </Form>
  );
}
