import * as z from "zod";
import { getFieldLabel } from "~/domain/common";
import { useLocalStorage } from "~/hooks";
import { toggleArrayElement } from "~/utils";
import { makeCellRenderer } from "./cells";
import type {
  AccessorColumn,
  Column,
  ForeignResourceRenderer,
  NormalizedColumn,
} from "./types";

const VISIBLE_COLUMNS_STORAGE_KEY_PREFIX = "column-visibility";
const visibleColumnsSchema = z.array(z.string());

export function useColumnVisibility<TResource extends object>(
  key: string,
  columns: ReadonlyArray<NormalizedColumn<TResource>>,
) {
  const [visibleColumnsHeaders, setVisibleColumnsHeaders] = useLocalStorage(
    `${VISIBLE_COLUMNS_STORAGE_KEY_PREFIX}.${key}`,
    columns.flatMap((column) => {
      if (column.defaultHidden) {
        return [];
      } else {
        return [column.header];
      }
    }),
    (value) => visibleColumnsSchema.parse(value),
  );

  return {
    visibleColumns: columns.filter(({ header }) =>
      visibleColumnsHeaders.includes(header),
    ),
    toggleColumnVisibility(column: NormalizedColumn<any>) {
      setVisibleColumnsHeaders(
        toggleArrayElement(visibleColumnsHeaders, column.header),
      );
    },
  };
}

/**
 * Return a list of sort keys or accessors from sortable columns. Useful to
 * derive a list of allowable columns for validation.
 */
export function getSortableFields<TResource extends object>(
  columns: ReadonlyArray<Column<TResource, any>>,
): Array<string & keyof TResource> {
  // Equivalent to filtering and mapping
  return columns.flatMap((column) => {
    const sortKey = getColumnSortKey(column);

    return sortKey !== undefined ? [sortKey] : [];
  });
}

export function normalizeColumn<
  TResource extends object,
  TForeignResources extends string,
>(
  column: Column<TResource, TForeignResources>,
  renderForeignResource?: ForeignResourceRenderer<TForeignResources>,
): NormalizedColumn<TResource> {
  const { defaultHidden = false } = column;

  const sortKey = getColumnSortKey(column);

  if (isAccessorColumn(column)) {
    const align = getColumnAlignment(column);

    return {
      align,
      defaultHidden,
      header: column.header ?? getFieldLabel(column.accessor),
      sortKey,
      renderCell: makeCellRenderer(column, renderForeignResource),
    };
  } else {
    return {
      align: column.align,
      defaultHidden,
      header: column.header,
      sortKey,
      renderCell: column.renderCell,
    };
  }
}

function getColumnAlignment(
  column: AccessorColumn<any, any>,
): NormalizedColumn<any>["align"] {
  if (column.align !== undefined) {
    return column.align;
  } else if (column.dataType === "number" || column.dataType === "bytes") {
    return "right";
  } else if (column.dataType === "boolean") {
    return "center";
  } else {
    return undefined;
  }
}

/**
 * Return the sorting key for the given column if one is available.
 *
 * If the column has a `sortKey` field, it is returned. If the column has
 * an `accessor` field, it is returned only if `isSortable === true`. Otherwise,
 * the column is not considered to have a sorting key.
 */
function getColumnSortKey<TResource extends object>(
  column: Column<TResource, any>,
): (string & keyof TResource) | undefined {
  if (isAccessorColumn(column) && column.isSortable) {
    return column.accessor;
  } else if ("sortKey" in column && column.sortKey !== undefined) {
    return column.sortKey;
  } else {
    return undefined;
  }
}

function isAccessorColumn<
  TResource extends object,
  TForeignResources extends string,
>(
  column: Column<TResource, TForeignResources>,
): column is AccessorColumn<TResource, TForeignResources> {
  return "accessor" in column;
}
