import React from "react";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { Button, List, ListSubheader, Typography } from "@mui/material";
import { renderQuery } from "~/components/QueryRenderer";
import { DigestionListItem } from "~/domain/digestions";
import { SidebarHeader } from "~/layout";
import { invariant } from "~/lib/invariant";
import { capitalize } from "~/lib/std";
import type { Digestion } from "~/lqs";
import { useCurrentUser, useDigestions } from "~/lqs";
import {
  calculateProcessRefetchInterval,
  checkIsProcessDynamic,
} from "~/lqs/components/process";
import { selectData } from "~/utils/queries";
import { useDigestionStateContext } from "./digestion-state-provider";
import { returnToEditing } from "./use-draft-digestion";

export function DigestionListSection() {
  const { draftDigestion, naming } = useDigestionStateContext();

  const currentUserQuery = useCurrentUser({
    select({ data }) {
      invariant(data != null, "Expected user to be present");

      return data;
    },
  });

  const digestionsQuery = useDigestions<Array<Digestion>>(
    {
      createdBy: currentUserQuery.data?.id,
      limit: 100,
      order: "created_at",
      sort: "desc",
      includeCount: false,
    },
    {
      enabled: currentUserQuery.isSuccess,
      select: selectData,
      refetchInterval(digestions) {
        if (digestions == null) {
          return false;
        }

        for (const digestion of digestions) {
          const calculatedInterval = calculateProcessRefetchInterval(digestion);
          if (typeof calculatedInterval === "number") {
            return calculatedInterval;
          }
        }

        return false;
      },
    },
  );

  function handleReturnToEditing(): void {
    draftDigestion.dispatch(returnToEditing());
  }

  return (
    <>
      <SidebarHeader title={`Your ${capitalize(naming)}s`} />
      <Button
        color="primary"
        variant="text"
        fullWidth
        startIcon={<KeyboardArrowLeft />}
        onClick={handleReturnToEditing}
        sx={{ mb: 2, justifyContent: "start" }}
      >
        Back to Draft
      </Button>
      {renderQuery(digestionsQuery, {
        loading: <Typography>Loading...</Typography>,
        error: <Typography>Unable to fetch your {naming}s</Typography>,
        success(digestions) {
          if (digestions.length === 0) {
            return (
              <Typography>You haven't created any {naming}s yet</Typography>
            );
          }

          const someDigestionsAreDynamic = digestions.some(
            checkIsProcessDynamic,
          );

          return (
            <List
              disablePadding
              sx={{ px: 1 }}
              subheader={
                someDigestionsAreDynamic && (
                  <ListSubheader
                    disableSticky
                    disableGutters
                    sx={{ lineHeight: "normal", mb: 1 }}
                  >
                    List will update until all {naming}s are complete
                  </ListSubheader>
                )
              }
            >
              {digestions.map((digestion) => (
                <DigestionListItem key={digestion.id} digestion={digestion} />
              ))}
            </List>
          );
        },
      })}
    </>
  );
}
