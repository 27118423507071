import * as z from "zod";
import type { Option } from "~/components/Form/types";
import { createErrorMap } from "~/domain/common";
import { ProcessState } from "../sdk";

export const processStateOptions: Option[] = Object.values(ProcessState).map(
  (value) => ({
    label: value,
    value,
  }),
);

export const requiredProcessStateSchema = z.nativeEnum(ProcessState, {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
    invalid_enum_value: "Unexpected value",
  }),
});

export const optionalProcessStateSchema = requiredProcessStateSchema.nullable();

export const filterProcessState = optionalProcessStateSchema.default(null);
