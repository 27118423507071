import React from "react";
import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { type UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { QueryRenderer } from "~/components/QueryRenderer";
import type { CountableListResponse } from "~/domain/common";
import { selectCountableListResponse } from "~/domain/common";
import type { ListLogsRequest, Log } from "~/lqs";
import { useLogs } from "~/lqs";
import type { LabelProcessor } from "./log-gallery";
import { LogGallery } from "./log-gallery";

export function LogThumbnails({
  logsQuery,
  processLabels,
  filters,
  pagination,
}: {
  logsQuery: UseQueryResult<CountableListResponse<Log>>;
  processLabels: LabelProcessor;
  filters: React.ReactNode;
  pagination: React.ReactNode;
}) {
  return (
    <Card>
      {filters}
      <Box sx={{ position: "relative", my: 2 }}>
        <Divider />
        {logsQuery.isFetching && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </Box>
      <QueryRenderer
        query={logsQuery}
        error={
          <Typography
            variant="h4"
            component="p"
            sx={{ textAlign: "center", my: 4 }}
          >
            An error occurred searching for logs
          </Typography>
        }
        success={(response) => {
          if (response.count === 0) {
            return (
              <Typography
                variant="h4"
                component="p"
                sx={{ textAlign: "center", my: 4 }}
              >
                No logs matched the filters
              </Typography>
            );
          } else {
            return (
              <LogGallery logs={response.data} processLabels={processLabels} />
            );
          }
        }}
      />
      {pagination && (
        <>
          <Divider sx={{ mb: 2 }} />
          {pagination}
        </>
      )}
    </Card>
  );
}

export function useLogThumbnailsQuery(
  request: ListLogsRequest,
): UseQueryResult<CountableListResponse<Log>> {
  return useLogs(
    {
      ...request,
      startTimeNull: false,
      endTimeNull: false,
      includeCount: true,
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );
}
