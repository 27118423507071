import type { RefCallback } from "react";
import React, { useCallback, useRef, useState } from "react";
import type { ButtonBaseActions } from "@mui/material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Dd, Dl, DlGroup, Dt } from "~/components/DescriptionList";
import type { Log } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { LogThumbnail } from "./log-thumbnail";
import { renderDuration, renderRecorded } from "./utils";

export function LogThumbnailCard({
  log,
  dense = false,
  actionRef,
  tags,
}: {
  log: Log;
  dense?: boolean;
  actionRef?: React.Ref<ButtonBaseActions>;
  tags?: React.ReactNode;
}) {
  const lqsNavigator = useLqsNavigator();

  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const [shouldLoad, ref] = useShouldLoadImages();

  const logNameVariant = dense ? "body1" : "h6";
  const logAttributeVariant = dense ? "body2" : "body1";

  const showTags = tags != null && tags !== false;

  let thumbnailWidth: string | number;
  let thumbnailHeight: string | number;
  if (showTags) {
    thumbnailWidth = 175;
    thumbnailHeight = "100%";
  } else {
    thumbnailWidth = "100%";

    if (dense) {
      thumbnailHeight = 175;
    } else {
      thumbnailHeight = 200;
    }
  }

  let logName;
  if (showTags) {
    logName = (
      <Link
        underline="hover"
        variant={logNameVariant}
        component={RouterLink}
        to={lqsNavigator.toPlayer({ logId: log.id })}
        sx={{
          wordBreak: "break-all",
          fontWeight: "bold",
        }}
      >
        {log.name}
      </Link>
    );
  } else {
    logName = (
      <Typography
        variant={logNameVariant}
        component="p"
        sx={{
          wordBreak: "break-all",
          fontWeight: "bold",
        }}
      >
        {log.name}
      </Typography>
    );
  }

  const innerContent = (
    <>
      <LogThumbnail
        log={log}
        width={thumbnailWidth}
        height={thumbnailHeight}
        shouldLoad={shouldLoad}
        cycle={hovered || focused}
      />
      <CardContent>
        <Stack spacing={2}>
          {logName}
          <Dl spacing={4}>
            <DlGroup xs={12} md="auto">
              <Dt variant={logAttributeVariant}>Recorded</Dt>
              <Dd variant={logAttributeVariant}>{renderRecorded(log)}</Dd>
            </DlGroup>
            <DlGroup xs={12} md="auto">
              <Dt variant={logAttributeVariant}>Duration</Dt>
              <Dd variant={logAttributeVariant}>{renderDuration(log)}</Dd>
            </DlGroup>
          </Dl>
          {tags}
        </Stack>
      </CardContent>
    </>
  );

  const commonProps = {
    sx: {
      display: "flex",
      flexDirection: showTags ? "row" : "column",
      alignItems: "start",
      ...(showTags && {
        justifyContent: "start",
      }),
    },
    onFocus() {
      setFocused(true);
    },
    onBlur() {
      setFocused(false);
    },
  };

  let content;
  if (showTags) {
    content = <Box {...commonProps}>{innerContent}</Box>;
  } else {
    content = (
      <CardActionArea
        {...commonProps}
        action={actionRef}
        component={RouterLink}
        to={lqsNavigator.toPlayer({ logId: log.id })}
        disableTouchRipple
      >
        {innerContent}
      </CardActionArea>
    );
  }

  return (
    <Card
      ref={ref}
      variant="outlined"
      sx={{
        width: 1,
        bgcolor: "inherit",
        overflow: "hidden",
        // Making this a flex container allows the card action area's children
        // to use relative lengths like "100%"
        display: "flex",
        // Required in Safari for an element with rounded corners to
        // hide its childrens' overflow
        isolation: "isolate",
      }}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      {content}
    </Card>
  );
}

function useShouldLoadImages() {
  const [shouldLoad, setShouldLoad] = useState(false);

  const observerRef = useRef<IntersectionObserver | null>(null);
  const observedElementRef = useRef<HTMLElement | null>(null);

  const ref: RefCallback<HTMLElement | null> = useCallback((element) => {
    if (observerRef.current === null) {
      observerRef.current = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;

          if (entry.isIntersecting) {
            // Once lazy loading begins, the target can be unobserved as
            // we no longer care about its intersection status
            observer.unobserve(entry.target);
            observedElementRef.current = null;

            setShouldLoad(true);
          }
        },
        {
          root: document.querySelector("[data-scroll-root]"),
          rootMargin: "0px 0px 400px 0px",
        },
      );
    }

    if (observedElementRef.current !== null) {
      // Stop observing previous element to avoid memory leaks
      observerRef.current.unobserve(observedElementRef.current);
    }

    observedElementRef.current = element;

    if (element !== null) {
      observerRef.current.observe(element);
    }
  }, []);

  return [shouldLoad, ref] as const;
}
