import { LoadingButton } from "@mui/lab";
import {
  Container,
  LinearProgress,
  Link,
  Stack,
  textFieldClasses,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Navigate } from "react-router-dom";
import * as z from "zod";
import { Card } from "~/components/Card";
import { Details } from "~/components/Details";
import { ResourceFields } from "~/components/DetailsCards";
import { TextField, useStudioForm } from "~/components/Form";
import { AuthenticationGuard } from "~/domain/auth";
import { optionalText } from "~/domain/common";
import { useCurrentDsmUser, useUserUpdateMutationOptionsFactory } from "~/dsm";
import { renderForeignResource } from "~/dsm/renderForeignResource";
import * as Layout from "~/layout";
import { invariant } from "~/lib/invariant";
import { pick } from "~/lib/std";
import { PRIVACY_POLICY_LINK } from "~/links";
import { useLqsNavigator } from "~/paths";

export function ProfilePage() {
  const lqsNavigator = useLqsNavigator();

  return (
    <Layout.Page title="Profile">
      <Container fixed maxWidth="md">
        <AuthenticationGuard
          unauthenticatedFallback={
            <Navigate to={lqsNavigator.toDashboard()} replace />
          }
        >
          <AuthenticatedContent />
        </AuthenticationGuard>
      </Container>
    </Layout.Page>
  );
}

const schema = z
  .object({
    firstName: optionalText,
    lastName: optionalText,
    phone: optionalText,
    company: optionalText,
    jobTitle: optionalText,
  })
  .superRefine((values, ctx) => {
    if (values.firstName != null && values.lastName == null) {
      ctx.addIssue({
        fatal: true,
        code: z.ZodIssueCode.custom,
        message: "Last name must also be provided",
        path: ["lastName"],
      });
      return;
    }

    if (values.lastName != null && values.firstName == null) {
      ctx.addIssue({
        fatal: true,
        code: z.ZodIssueCode.custom,
        message: "First name must also be provided",
        path: ["firstName"],
      });
      return;
    }
  });

function AuthenticatedContent() {
  const profileQuery = useCurrentDsmUser({
    select({ data }) {
      invariant(data != null, "Expected user to be present");

      return data;
    },
  });

  const disabled = !profileQuery.isSuccess;
  const progressVisibility = profileQuery.isSuccess ? "hidden" : "visible";

  const { enqueueSnackbar } = useSnackbar();
  const updateUserMutationFactory = useUserUpdateMutationOptionsFactory();
  const updateUserMutation = useMutation(
    updateUserMutationFactory(profileQuery.data?.id),
  );

  const { control, handleSubmit } = useStudioForm({
    schema,
    onSubmit(values) {
      updateUserMutation.mutate(values, {
        onSuccess() {
          enqueueSnackbar("Profile updated", { variant: "success" });
        },
        onError() {
          enqueueSnackbar("Unable to update profile", { variant: "error" });
        },
      });
    },
    defaultValues: {
      firstName: null,
      lastName: null,
      phone: null,
      company: null,
      jobTitle: null,
    },
    values: profileQuery.isSuccess
      ? pick(profileQuery.data, [
          "firstName",
          "lastName",
          "phone",
          "company",
          "jobTitle",
        ])
      : undefined,
  });

  return (
    <Stack direction="column" spacing={4}>
      <Card title="Personal Information">
        <LinearProgress
          // Remount when made visible so animation restarts
          key={progressVisibility}
          sx={{ visibility: progressVisibility, mb: 2 }}
        />
        <Stack spacing={2} component="form" noValidate onSubmit={handleSubmit}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              flexWrap: "wrap",
              [`& .${textFieldClasses.root}`]: {
                flex: "1 1",
                minWidth: "25ch",
              },
            }}
          >
            <TextField
              control={control}
              name="firstName"
              disabled={disabled}
              autoComplete="given-name"
            />
            <TextField
              control={control}
              name="lastName"
              disabled={disabled}
              autoComplete="family-name"
            />
          </Stack>
          <TextField
            control={control}
            name="phone"
            disabled={disabled}
            autoComplete="tel"
            inputMode="tel"
          />
          <TextField
            control={control}
            name="company"
            disabled={disabled}
            autoComplete="organization"
          />
          <TextField
            control={control}
            name="jobTitle"
            disabled={disabled}
            autoComplete="organization-title"
          />
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            disabled={disabled}
            loading={updateUserMutation.isLoading}
            sx={{ alignSelf: "baseline" }}
          >
            Update
          </LoadingButton>
          <Details>
            <Details.Summary>
              How do we handle your information?
            </Details.Summary>
            <Details.Content>
              You can find out how we handle your information by reading our{" "}
              <Link href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener">
                privacy policy
              </Link>
              .
            </Details.Content>
          </Details>
        </Stack>
      </Card>
      <Card title="Account">
        <ResourceFields
          query={profileQuery}
          fields={[
            {
              accessor: "username",
              dataType: "text",
            },
          ]}
          renderForeignResource={renderForeignResource}
        />
      </Card>
    </Stack>
  );
}
