import type { Draft } from "immer";
import { produce } from "immer";
import type { ImmerReducer } from "use-immer";
import { invariant } from "~/lib/invariant";
import { clamp, get, pull } from "~/lib/std";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import type { PointerLocation } from "../hooks";
import type { PlayerRecord } from "../record-store";
import type {
  ContainerNode,
  InitializedPanelNode,
  LayoutNode,
  PanelNode,
  TopicSelectionConfig,
  UninitializedPanelNode,
} from "./api";
import type { SplitOrientation } from "./constants";
import {
  MAX_IMAGE_BRIGHTNESS_PCT,
  MAX_IMAGE_CONTRAST_PCT,
  ClipInsetSide,
  FlipDirection,
  IMAGE_ROTATION_STEP_MAGNITUDE_DEG,
  MAX_CHART_FIELDS,
  RotationDirection,
  VisualizationType,
} from "./constants";
import type { VisualizationFilter } from "./topic-config";
import { getDefaultVisualization } from "./topic-config";
import { calculateRotationQuadrant, walkLayoutTree } from "./utils";

interface LayoutState {
  root: LayoutNode;
  nextId: number;
}

type OverridableInitializationState = Pick<
  InitializedPanelNode,
  "colorizeImage"
>;

export type PanelInitializationStateOverrider = (
  topic: Topic,
  defaultVisualization: VisualizationType,
) => Partial<OverridableInitializationState> | undefined | void;

interface PanelLayoutActionRegistry {
  /**
   * Split a panel, creating a new, uninitialized sibling panel. The orientation
   * describes if the sibling should be to the right of or below the base panel.
   */
  "split-panel": {
    panelId: PanelNode["id"];
    orientation: SplitOrientation;
  };
  /**
   * Remove a panel from the tree entirely. If the panel is the only one in the
   * tree, a new, uninitialized panel replaces it.
   */
  "remove-panel": {
    panelId: PanelNode["id"];
  };
  /**
   * Resize a panel or container. The flex describes its relative size compared
   * to its sibling.
   */
  "resize-node": {
    nodeId: LayoutNode["id"];
    flex: number;
  };
  /**
   * Load a layout, overwriting the existing layout completely. Useful to load
   * layout profiles kept in a user's web storage.
   */
  "load-layout": {
    layout: LayoutNode;
  };
  "set-topic-search-filter": {
    panelId: PanelNode["id"];
    search: string | null;
  };
  "set-topic-visualization-filter": {
    panelId: PanelNode["id"];
    filterName: VisualizationFilter | null;
  };
  /**
   * Initialize the panel using the given topic. The panel's
   * visualization-related fields will be given default values. The initial
   * visualization depends on the topic's message type, if any:
   *  - If the message type supports image data, the panel will be initialized
   *    to the "image" visualization
   *  - If the message type supports GPS data, the panel will be initialized to
   *    the "map" visualization
   *  - For all other message types, the panel will be initialized to the
   *    "timeline" visualization
   *
   * Invariants:
   *  - Panel must be uninitialized
   */
  "select-topic": {
    panelId: PanelNode["id"];
    topic: Topic;
  };
  /**
   * Adjust the image's brightness, given as a percentage.
   *
   * Invariants:
   *  - Panel must be initialized
   *  - Brightness must be in the range [0, {@link MAX_IMAGE_BRIGHTNESS_PCT}]
   */
  "adjust-image-brightness": {
    panelId: PanelNode["id"];
    brightness: number;
  };
  /**
   * Adjust the image's contrast, given as a percentage.
   *
   * Invariants:
   *  - Panel must be initialized
   *  - Contrast must be in the range [0, {@link MAX_IMAGE_CONTRAST_PCT}]
   */
  "adjust-image-contrast": {
    panelId: PanelNode["id"];
    contrast: number;
  };
  /**
   * Toggle image colorization
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "toggle-image-colorization": {
    panelId: PanelNode["id"];
    colorize: boolean;
  };
  /**
   * Rotate the displayed image 90 degrees in the specified direction
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "rotate-image": {
    panelId: PanelNode["id"];
    direction: RotationDirection;
  };
  /**
   * Set or clear the direction in which the image should be flipped.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "set-image-flip-direction": {
    panelId: PanelNode["id"];
    flipDirection: FlipDirection | null;
  };
  /**
   * Set or clear the inference topic to be visualized in this panel alongside
   * the main topic.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "set-inference-topic": {
    panelId: PanelNode["id"];
    inferenceTopic: Topic | null;
  };
  "toggle-inference-transform-lock": {
    panelId: PanelNode["id"];
    lock: boolean;
  };
  /**
   * Adds a field to be plotted in the chart visualization. The field can be
   * top-level or nested and must be available in the `data` payload
   * argument. The field must follow the syntax for lodash's `get` method. The
   * value at the given field should be a numeric type.
   *
   * There are some circumstances under which the field will not be added,
   * though these circumstances do not represent an error:
   *  - Maximum number of fields already added
   *  - Field has already been added
   *  - Field value is not a numeric type
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "add-chart-field": {
    panelId: PanelNode["id"];
    field: string;
    data: PlayerRecord<"default">["data"];
  };
  /**
   * Removes the given field from the panel's chart visualization fields.
   *
   * Invariants:
   *  - Panel must be initialized
   *  - Field must have previously been added
   */
  "remove-chart-field": {
    panelId: PanelNode["id"];
    field: string;
  };
  /**
   * Selects the provided tag for the panel, unless that tag is already
   * selected, in which case the tag will be un-selected.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "select-tag": {
    panelId: PanelNode["id"];
    tag: string;
  };
  /**
   * Change whether a detection-type inference output's bounding boxes should be
   * drawn.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "show-detection-bounding-boxes": {
    panelId: PanelNode["id"];
    showDetectionBoundingBoxes: boolean;
  };
  /**
   * Change whether a detection-type inference output's detection class names
   * should be displayed.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "show-detection-class-names": {
    panelId: PanelNode["id"];
    showDetectionClassNames: boolean;
  };
  /**
   * Change the visibility for this class of detected objects.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "change-object-class-visibility": {
    panelId: PanelNode["id"];
    className: string;
    hideClass: boolean;
  };
  /**
   * Set the opacity for the segmentation and depth-estimation inference result
   * image overlay.
   *
   * Invariants:
   *   - Panel must be initialized
   *   - Opacity must be in the range [0, 1]
   */
  "set-inference-image-opacity": {
    panelId: PanelNode["id"];
    opacity: number;
  };
  /**
   * Toggle inference image colorization.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "toggle-inference-image-colorization": {
    panelId: PanelNode["id"];
    colorize: boolean;
  };
  /**
   * Set the new inference image clip inset taking into consideration
   * transformations.
   *
   * Invariants:
   *   - Panel must be initialized
   */
  "set-inference-image-clip-inset": {
    panelId: PanelNode["id"];
    pointerLocation: PointerLocation;
    baseImageDimensions: { naturalWidth: number; naturalHeight: number };
  };
  /**
   * Set the size of individual points in the 3D point cloud.
   *
   * Invariants:
   *   - Panel must be initialized
   *   - Point size must be in the range [0, 0.1]
   */
  "set-point-cloud-point-size": {
    panelId: PanelNode["id"];
    pointSize: number;
  };
  /**
   * Switches the panel to the given visualization. To choose the "chart"
   * visualization, at least one field must have already been selected.
   *
   * Invariants:
   *  - Panel must be initialized
   *  - If `visualization === "chart"`, must be > 0 fields added
   */
  "choose-visualization": {
    panelId: PanelNode["id"];
    tab: VisualizationType;
  };
  /**
   * Clears the selected topic for the panel, returning the panel to an
   * uninitialized state.
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "choose-new-topic": {
    panelId: PanelNode["id"];
  };
  /**
   * Mark the panel as having been used to initiate an auto-skip playback
   * action. The record will be kept until the panel is closed or its topic
   * is changed. Changing the panel's visualization will not clear the record.
   *
   * Invariants:
   *  - Panel must be initialized
   */
  "record-auto-skip": {
    panelId: InitializedPanelNode["id"];
  };
}

export type PanelLayoutAction<
  TActionType extends
    keyof PanelLayoutActionRegistry = keyof PanelLayoutActionRegistry,
> = {
  [ActionType in TActionType]: {
    type: ActionType;
    payload: PanelLayoutActionRegistry[ActionType];
  };
}[TActionType];

function createActionCreator<
  const TActionType extends keyof PanelLayoutActionRegistry,
>(
  type: TActionType,
): (
  payload: PanelLayoutActionRegistry[TActionType],
) => PanelLayoutAction<TActionType> {
  return function createPanelLayoutAction(payload) {
    return {
      type,
      payload,
    };
  };
}

export const splitPanel = createActionCreator("split-panel");

export const removePanel = createActionCreator("remove-panel");

export const resizeNode = createActionCreator("resize-node");

export const loadLayout = createActionCreator("load-layout");

export const setTopicSearchFilter = createActionCreator(
  "set-topic-search-filter",
);

export const setTopicVisualizationFilter = createActionCreator(
  "set-topic-visualization-filter",
);

export const selectTopic = createActionCreator("select-topic");

export const adjustImageBrightness = createActionCreator(
  "adjust-image-brightness",
);

export const adjustImageContrast = createActionCreator("adjust-image-contrast");

export const toggleImageColorization = createActionCreator(
  "toggle-image-colorization",
);

export const rotateImage = createActionCreator("rotate-image");

export const setImageFlipDirection = createActionCreator(
  "set-image-flip-direction",
);

export const setInferenceTopic = createActionCreator("set-inference-topic");

export const toggleInferenceTransformLock = createActionCreator(
  "toggle-inference-transform-lock",
);

export const addChartField = createActionCreator("add-chart-field");

export const removeChartField = createActionCreator("remove-chart-field");

export const selectTag = createActionCreator("select-tag");

export const showDetectionBoundingBoxes = createActionCreator(
  "show-detection-bounding-boxes",
);

export const showDetectionClassNames = createActionCreator(
  "show-detection-class-names",
);

export const changeObjectClassVisibility = createActionCreator(
  "change-object-class-visibility",
);

export const setInferenceImageOpacity = createActionCreator(
  "set-inference-image-opacity",
);

export const toggleInferenceImageColorization = createActionCreator(
  "toggle-inference-image-colorization",
);

export const setInferenceImageClipInset = createActionCreator(
  "set-inference-image-clip-inset",
);

export const setPointCloudPointSize = createActionCreator(
  "set-point-cloud-point-size",
);

export const recordAutoSkip = createActionCreator("record-auto-skip");

export const chooseVisualization = createActionCreator("choose-visualization");

export const chooseNewTopic = createActionCreator("choose-new-topic");

export const emptyLayout: LayoutNode = createUninitializedPanel(0, null, 1, {
  search: null,
  [VisualizationType.Image]: false,
  [VisualizationType.ThreeD]: false,
  [VisualizationType.Map]: false,
  lastSelected: null,
});

export function createReducer(
  initialLayout: LayoutNode,
  overrideInitializationState?: PanelInitializationStateOverrider,
): ImmerReducer<{ state: LayoutState | null }, PanelLayoutAction> {
  return function reducer(_draft, action) {
    // Storing the real state as a property makes it easier to overwrite an
    // initial `null` state value. The `produce` call is needed so
    // `initialLayout` is drafted and the reducer doesn't accidentally
    // mutate the original object.
    _draft.state = produce(
      _draft.state ?? getStateFromLayout(initialLayout),
      (draft) => {
        switch (action.type) {
          case "split-panel": {
            const panel = getPanel(draft, action.payload.panelId);

            const panelParentId = panel.parentId;

            const siblingId = draft.nextId++;
            const newParentId = draft.nextId++;

            const sibling = createUninitializedPanel(
              siblingId,
              newParentId,
              0.5,
              {
                search: null,
                [VisualizationType.Image]: false,
                [VisualizationType.ThreeD]: false,
                [VisualizationType.Map]: false,
                lastSelected: null,
              },
            );

            const newParent: ContainerNode = {
              type: "container",
              parentId: panelParentId,
              id: newParentId,
              flex: panel.flex,
              orientation: action.payload.orientation,
              firstChild: panel,
              secondChild: sibling,
            };

            panel.parentId = newParentId;
            panel.flex = 0.5;

            replaceNode(draft, panelParentId, panel, newParent);

            return;
          }
          case "remove-panel": {
            const panel = getPanel(draft, action.payload.panelId);

            const panelParentId = panel.parentId;

            if (panelParentId === null) {
              draft.root = createUninitializedPanel(draft.nextId++, null, 1, {
                search: null,
                [VisualizationType.Image]: false,
                [VisualizationType.ThreeD]: false,
                [VisualizationType.Map]: false,
                lastSelected: null,
              });
            } else {
              const parent = getContainer(draft, panelParentId);

              const sibling =
                panel === parent.firstChild
                  ? parent.secondChild
                  : parent.firstChild;

              replaceNode(draft, parent.parentId, parent, sibling);

              sibling.parentId = parent.parentId;

              if (parent.parentId === null) {
                sibling.flex = 1;
              } else {
                sibling.flex = parent.flex;
              }
            }

            return;
          }
          case "resize-node": {
            const node = getNode(draft, action.payload.nodeId);

            node.flex = action.payload.flex;

            return;
          }
          case "load-layout": {
            return getStateFromLayout(action.payload.layout);
          }
          case "set-topic-search-filter": {
            const panel = getUninitializedPanel(draft, action.payload.panelId);

            panel.topicSelection.search = action.payload.search;

            return;
          }
          case "set-topic-visualization-filter": {
            const panel = getUninitializedPanel(draft, action.payload.panelId);

            panel.topicSelection[VisualizationType.Image] = false;
            panel.topicSelection[VisualizationType.ThreeD] = false;
            panel.topicSelection[VisualizationType.Map] = false;

            if (action.payload.filterName !== null) {
              panel.topicSelection[action.payload.filterName] = true;
            }

            return;
          }
          case "select-topic": {
            const panel = getUninitializedPanel(draft, action.payload.panelId);

            const initializedPanel = createInitializedPanel(
              panel.id,
              panel.parentId,
              panel.flex,
              action.payload.topic,
              panel.topicSelection,
              overrideInitializationState,
            );

            replaceNode(
              draft,
              initializedPanel.parentId,
              panel,
              initializedPanel,
            );

            return;
          }
          case "adjust-image-brightness": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const {
              payload: { brightness },
            } = action;

            invariant(
              0 <= brightness && brightness <= MAX_IMAGE_BRIGHTNESS_PCT,
              `Brightness must be in the range [0, ${MAX_IMAGE_BRIGHTNESS_PCT}]`,
            );

            panel.imageBrightnessPct = brightness;

            return;
          }
          case "adjust-image-contrast": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const {
              payload: { contrast },
            } = action;

            invariant(
              0 <= contrast && contrast <= MAX_IMAGE_CONTRAST_PCT,
              `Contrast must be in the range [0, ${MAX_IMAGE_CONTRAST_PCT}]`,
            );

            panel.imageContrastPct = contrast;

            return;
          }
          case "toggle-image-colorization": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            panel.colorizeImage = action.payload.colorize;

            return;
          }
          case "rotate-image": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            panel.imageRotationDeg = calculateNewRotationDeg(
              panel.imageRotationDeg,
              action.payload.direction,
            );

            if (panel.lockInferenceTransform) {
              panel.inferenceRotationDeg = calculateNewRotationDeg(
                panel.inferenceRotationDeg,
                action.payload.direction,
              );
            }

            return;
          }
          case "set-image-flip-direction": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const {
              payload: { flipDirection },
            } = action;

            if (panel.imageFlipDirection === flipDirection) {
              // Probably shouldn't happen but it's a no-op
              return;
            }

            if (panel.lockInferenceTransform) {
              // Locked output overlay's initial flip direction might not correspond
              // to the image's. For example, the image may be horizontally reflected
              // and the user needed to flip it horizontally and _then_ lock the
              // overlay. In such a situation, the two can never have the same
              // flip direction *but* they'll only be a reflection of each other along
              // a single axis (since reflection along both axes isn't permitted by
              // Studio as it's just a 180-degree rotation).
              //
              // The overlay's stored flip direction and rotation should always
              // represent the transformations necessary to keep the it aligned
              // relative to the image in the same manner as when the user locked
              // the overlay (or when the panel was initialized as locked is the
              // default setting).

              const currentFlipDirections = new Set([
                panel.imageFlipDirection,
                panel.inferenceFlipDirection,
              ]);

              const containsOrthogonalFlips =
                currentFlipDirections.has(FlipDirection.X) &&
                currentFlipDirections.has(FlipDirection.Y);

              invariant(
                !containsOrthogonalFlips,
                "Images and segmentations should not be flipped orthogonally",
              );

              if (currentFlipDirections.size === 1) {
                // Both image and segmentations share the same flip direction, so
                // they should remain that way
                panel.inferenceFlipDirection = flipDirection;
              } else if (currentFlipDirections.has(flipDirection)) {
                // By this point, the segmentations are guaranteed to be reflected
                // relative to the other along some axis *and* the payload's flip
                // direction matches the segmentations'. In this case, the
                // segmentations' and image's flip directions need to be swapped.
                panel.inferenceFlipDirection = panel.imageFlipDirection;
              } else {
                // By this point, the payload flip direction is guaranteed to be the
                // x or y axis but doesn't correspond to either the image's or the
                // segmentations' flip directions. Since the segmentations are
                // reflected relative to the image, applying this second flip along
                // the orthogonal axis is equivalent to rotating 180 degrees.
                panel.inferenceFlipDirection = null;
                panel.inferenceRotationDeg +=
                  2 * IMAGE_ROTATION_STEP_MAGNITUDE_DEG;
              }
            }

            // Image's final flip direction is always the payload's flip direction
            panel.imageFlipDirection = flipDirection;

            return;
          }
          case "set-inference-topic": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            panel.inferenceTopicName =
              action.payload.inferenceTopic?.name ?? null;

            return;
          }
          case "toggle-inference-transform-lock": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            panel.lockInferenceTransform = action.payload.lock;

            if (!action.payload.lock) {
              panel.inferenceRotationDeg = 0;
              panel.inferenceFlipDirection = null;
            }

            return;
          }
          case "add-chart-field": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const { field, data } = action.payload;

            if (panel.fields.length >= MAX_CHART_FIELDS) {
              return;
            }

            if (panel.fields.includes(field)) {
              return;
            }

            if (typeof get(data, field) !== "number") {
              return;
            }

            panel.fields.push(field);

            return;
          }
          case "remove-chart-field": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const { field } = action.payload;

            invariant(
              panel.fields.includes(field),
              `Field not currently selected: "${field}"`,
            );

            pull(panel.fields, field);

            return;
          }
          case "select-tag": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            if (panel.selectedTag === action.payload.tag) {
              panel.selectedTag = null;
            } else {
              panel.selectedTag = action.payload.tag;
            }

            return;
          }
          case "show-detection-bounding-boxes": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            panel.showDetectionBoundingBoxes =
              action.payload.showDetectionBoundingBoxes;

            return;
          }
          case "show-detection-class-names": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            panel.showDetectionClassNames =
              action.payload.showDetectionClassNames;

            return;
          }
          case "change-object-class-visibility": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const {
              payload: { className, hideClass },
            } = action;

            const isCurrentlyHidden =
              panel.hiddenObjectClassNames.includes(className);

            if (isCurrentlyHidden && !hideClass) {
              pull(panel.hiddenObjectClassNames, className);
            } else if (!isCurrentlyHidden && hideClass) {
              panel.hiddenObjectClassNames.push(className);
            }

            return;
          }
          case "set-inference-image-opacity": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const {
              payload: { opacity },
            } = action;

            invariant(
              0 <= opacity && opacity <= 1,
              "Opacity must be in range [0, 1]",
            );

            panel.inferenceImageOpacity = opacity;

            return;
          }
          case "toggle-inference-image-colorization": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            panel.colorizeInferenceImage = action.payload.colorize;

            return;
          }
          case "set-inference-image-clip-inset": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const {
              inferenceRotationDeg,
              inferenceFlipDirection,
              inferenceImageClipInset: { side: insetSide },
            } = panel;

            // The inset needs to be calculated differently depending on the
            // inset side:
            // 1. For left and right sides, use the x coordinate and width
            // 2. For top and bottom sides, use the y coordinate and height
            // 3. For left and top sides, don't need to invert the percentage
            // 4. For right and bottom sides, need to invert the percentage
            //
            // Inversion is necessary for the right and bottom sides because
            // the pointer coordinates are always relative to the top left
            // corner of the container, ignoring transforms.
            //
            // Once transforms are thrown into the mix, it's no longer as simple
            // as using a lookup table. Rather, the percentage needs to be
            // calculated from where the base side *appears* to be after
            // transforms are applied. If, for example, the inset is from the
            // left side but transforms make the image's left side appear as
            // the bottom side of the container, then the new inset percentage
            // needs to be calculated using the parameters for the bottom side.
            //
            // This array's order is important for the index calculations that
            // happen below. While the starting element is arbitrary, it's
            // crucial each successive element is the next apparent side if you
            // were to rotate the image once (in the positive direction). So, if
            // we have an inset from the left side and rotate the image twice,
            // the inset would appear to be the right side on screen. Add 2 (for
            // the number of rotations) to the index of the left side's params
            // and you end up at the params for the right side.
            const sideCalculationParams = [
              { axis: "x", invert: false } /* left */,
              { axis: "y", invert: false } /* top */,
              { axis: "x", invert: true } /* right */,
              { axis: "y", invert: true } /* bottom */,
            ];

            // Get the index into the params lookup table according to the base
            // inset side. This MUST have the same order as the elements in the
            // array above.
            let sideIndex = [
              ClipInsetSide.Left,
              ClipInsetSide.Top,
              ClipInsetSide.Right,
              ClipInsetSide.Bottom,
            ].indexOf(insetSide);

            if (
              sideCalculationParams[sideIndex].axis === inferenceFlipDirection
            ) {
              // A flip along the same axis as the mouse coordinate that'll
              // be used is visually equivalent to 2 rotations
              sideIndex += 2;
            }

            sideIndex += calculateRotationQuadrant(inferenceRotationDeg);

            const apparentSideCalculationParams =
              sideCalculationParams[sideIndex % sideCalculationParams.length];

            const {
              payload: { pointerLocation },
            } = action;

            // TODO: Calculate relative to scaled image dimensions, not container
            let insetPercent: number;

            if (apparentSideCalculationParams.axis === "x") {
              insetPercent = pointerLocation.x / pointerLocation.width;
            } else {
              insetPercent = pointerLocation.y / pointerLocation.height;
            }

            if (apparentSideCalculationParams.invert) {
              insetPercent = 1 - insetPercent;
            }

            panel.inferenceImageClipInset.percent = clamp(insetPercent, 0, 1);

            return;
          }
          case "set-point-cloud-point-size": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const {
              payload: { pointSize },
            } = action;

            invariant(
              0 <= pointSize && pointSize <= 0.1,
              "Point size must be in range [0, 0.1]",
            );

            panel.pointCloudPointSize = pointSize;

            return;
          }
          case "choose-visualization": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            invariant(
              action.payload.tab !== "chart" || panel.fields.length > 0,
              "Must have at least 1 field selected to show chart",
            );

            panel.visualization = action.payload.tab;

            return;
          }
          case "choose-new-topic": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            const uninitializedPanel = createUninitializedPanel(
              panel.id,
              panel.parentId,
              panel.flex,
              panel.topicSelection,
            );

            // Setting this when clearing the panel's topic rather than when
            // selecting it ensures the feature works when clearing the topic
            // for a panel loaded from a layout.
            uninitializedPanel.topicSelection.lastSelected = panel.topicName;

            replaceNode(
              draft,
              uninitializedPanel.parentId,
              panel,
              uninitializedPanel,
            );

            return;
          }
          case "record-auto-skip": {
            const panel = getInitializedPanel(draft, action.payload.panelId);

            panel.hasAutoSkipped = true;

            return;
          }
          default: {
            assertNever(action);
          }
        }
      },
    );
  };
}

// Helpers

function getStateFromLayout(layout: LayoutNode): LayoutState {
  let maxId = layout.id;
  walkLayoutTree(layout, (node) => {
    maxId = Math.max(maxId, node.id);
  });

  return {
    root: layout,
    nextId: maxId + 1,
  };
}

function createUninitializedPanel(
  id: PanelNode["id"],
  parentId: PanelNode["parentId"],
  flex: PanelNode["flex"],
  topicSelection: TopicSelectionConfig,
): UninitializedPanelNode {
  return {
    type: "panel",
    parentId,
    id,
    flex,
    isInitialized: false,
    topicSelection,
  };
}

function createInitializedPanel(
  id: PanelNode["id"],
  parentId: PanelNode["parentId"],
  flex: PanelNode["flex"],
  topic: Topic,
  topicSelection: TopicSelectionConfig,
  overrideInitializationState: PanelInitializationStateOverrider | undefined,
): InitializedPanelNode {
  const topicTypeName = topic.typeName;

  const defaultVisualization = getDefaultVisualization(topicTypeName);

  return {
    type: "panel",
    id,
    parentId,
    flex,
    isInitialized: true,
    topicSelection,
    topicName: topic.name,
    topicTypeName,
    visualization: defaultVisualization,
    hasAutoSkipped: false,
    fields: [],
    selectedTag: null,
    imageBrightnessPct: 1,
    imageContrastPct: 1,
    colorizeImage: false,
    imageRotationDeg: 0,
    imageFlipDirection: null,
    inferenceTopicName: null,
    lockInferenceTransform: true,
    inferenceRotationDeg: 0,
    inferenceFlipDirection: null,
    showDetectionBoundingBoxes: true,
    showDetectionClassNames: true,
    hiddenObjectClassNames: [],
    inferenceImageOpacity: 1,
    inferenceImageClipInset: {
      side: ClipInsetSide.Left,
      percent: 0.5,
    },
    colorizeInferenceImage: false,
    pointCloudPointSize: 0.05,
    ...overrideInitializationState?.(topic, defaultVisualization),
  };
}

function replaceNode(
  draft: Draft<LayoutState>,
  parentId: LayoutNode["parentId"],
  currentNode: LayoutNode,
  replacementNode: LayoutNode,
): void {
  if (parentId === null) {
    draft.root = replacementNode;
  } else {
    const parent = getContainer(draft, parentId);

    if (currentNode === parent.firstChild) {
      parent.firstChild = replacementNode;
    } else {
      parent.secondChild = replacementNode;
    }
  }
}

function getUninitializedPanel(
  draft: Draft<LayoutState>,
  panelId: PanelNode["id"],
): UninitializedPanelNode {
  const panel = getPanel(draft, panelId);

  invariant(
    !panel.isInitialized,
    `Panel with ID ${panelId} is already initialized`,
  );

  return panel;
}

function getInitializedPanel(
  draft: Draft<LayoutState>,
  panelId: PanelNode["id"],
): InitializedPanelNode {
  const panel = getPanel(draft, panelId);

  invariant(panel.isInitialized, `Panel with ID ${panelId} is not initialized`);

  return panel;
}

function getPanel(
  draft: Draft<LayoutState>,
  nodeId: PanelNode["id"],
): PanelNode {
  const node = getNode(draft, nodeId);

  invariant(node.type === "panel", `Node with ID ${nodeId} is not a panel`);

  return node;
}

function getContainer(
  draft: Draft<LayoutState>,
  nodeId: ContainerNode["id"],
): ContainerNode {
  const node = getNode(draft, nodeId);

  invariant(
    node.type === "container",
    `Node with ID ${nodeId} is not a container`,
  );

  return node;
}

function getNode(
  draft: Draft<LayoutState>,
  nodeId: LayoutNode["id"],
): LayoutNode {
  let maybeNode: LayoutNode | undefined = undefined;

  function visitor(node: LayoutNode) {
    if (node.id === nodeId) {
      maybeNode = node;
      return false;
    }
  }

  walkLayoutTree(draft.root, visitor);

  invariant(maybeNode !== undefined, `Node with ID ${nodeId} does not exist`);

  return maybeNode;
}

function calculateNewRotationDeg(
  currentRotationDeg: number,
  direction: RotationDirection,
): number {
  const rotateByDeg =
    direction === RotationDirection.Left
      ? -IMAGE_ROTATION_STEP_MAGNITUDE_DEG
      : IMAGE_ROTATION_STEP_MAGNITUDE_DEG;

  return currentRotationDeg + rotateByDeg;
}
